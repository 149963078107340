export default {
    created() {
        this.listJobLevels();
        this.listJobFunctions();
        this.listJobStartDates();
        this.listCompanyIndustries();
        this.listCompanyEmployeeSizes();
        this.listCompanyRevenueBands();
        this.listCompanyNAICS();
        this.listCompanySICS();
        this.listContactRegions();
        this.listContactCountries();
        this.listContactStates();
        this.listContactGenders();
        // this.listLastUpdatedDates();
        this.getContactLimitsOnLoad()

        let uid = this.$route.params.id
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.isResellerPrimaryAccount = token[uid].isResellerPrimaryAccount;
        this.isResellerSubaccount = token[uid].isResellerSubaccount;
    },
    data: () => ({
        showEntriesOptions: [
            { key: 10, value: 10 },
            { key: 25, value: 25 },
            { key: 50, value: 50 },
            { key: 100, value: 100 },
            { key: 500, value: 500 },
            { key: 1000, value: 1000 }
        ],

        downloadExcelContactPopup: false,
        downloadContacts: null,
        creditLimitWarningPopup: false,
        exportContactType: null,
        openExportContactOptionsPopup: false,
        //Job Criteria
        jobTitleEntered: [],
        jobLevelSelected: [],
        jobFunctionSelected: [],
        jobStartDateSelected: null,
        recentChangedJob: false,

        //Company Criteria
        companyNameEntered: [],
        companyWebAddressEntered: [],
        companyIndustrySelected: [],
        companyEmployeeSizeSelected: [],
        companyRevenueBandsSelected: [],
        companyNAICSSelected: [],
        companySICSSelected: [],

        //Location Criteria
        contactRegionSelected: [],
        addressTypeSelected: [],
        contactCountriesSelected: [],
        contactStatesSelected: [],
        contactCityEntered: [],
        contactZipCodeEntered: [],
        // localAddressRequired: false,

        //personal Criteria
        personalContactFirstNameEntered: null,
        personalContactLastNameEntered: null,
        personalContactGenderSelected: [],
        personalContactAlmaMaterEntered: [],
        personalContactBirthdayFromEntered: 0,
        personalContactBirthdayToEntered: 0,
        personalContactSkillsEntered: [],
        personalContactFormerEmployerEntered: [],
        // personalContactMinimumConnectionEntered: null,
        personalContactEmailAddressRequired: false,
        personalContactPhoneRequired: false,

        //Suppression Criteria
        // suppressionLastUpdatedDateSelected: null,
        suppressionCompanyWebAddressEntered: [],
        suppressionContactTitlesEntered: [],
        suppressionEmailAddressEntered: [],
        suppressionExcludeMyLeads: false,

        //List Criteria
        listPrimaryContact: false,
        listProfessionalEmail: false,
        listEmailConfidenceScore: 50,
        listDeterministicEmail: false,
        listPhoneNumber: false,

        tagsTempArr: [],

        searchContactsParams: null,
        totalContacts: 0,
        totalPages: 0,
        recordPerMainPage: 10,

        contacts: [],
        searchTipsPopup: false,

        selectedMain: [],

        creditWarningPopup: false,
        contactsDetailPopup: false,
        numberOfCreditMessage: null,
        selectedContactId: null,
        contactDetails: null,
        isCreditDeductionEnable: false,

        companyDetailPopup: false,
        selectedInner: [],
        companyDetail: null,
        companySearchContactsParams: null,
        companyTotalContacts: 0,
        companyTotalPages: 0,
        recordPerInnerPage: 10,

        totalContactLimitRemaining: 0,
        isContactDetailsPopupForSaved: false,

        selecteContactWarningForAddToMySavedPopup: false,
        selecteContactWarningForAddToMySavedPopupMessage: null,

        addabelContactsCount: 0,
        openAddToMySavedContactsCreditPopup: false,
        openAddToMySavedContactsCreditPopupMessage: null,

        addToMySavedContactsSuccessPopup: false,

        dynamicLocationColumnName: 'HQ Location',

        searchContactExportType: null,
        addTagsPopup: false,
        addUpdateTagPopup: false,
        manageTagsPopup: false,
        removeTagPopup: false,
        selectTagsOptions: [],
        userDefinedTagDetails: null,
        tagModuleRequest: null,
        tagRemoveList: null,

        //Query result to csv
        addJobNamePopup: false,
        dataEnrichmentJobName: null,
        exportJobRequestedMessage: false,
        jobMessage: "",
        checkIsSearchQuery: [],
        jobNameErrorMessage: "",
        showJobNameErrorMessage: false,

        // download limit is exceeded
        isExceededDownloadLimit: false,
        downloadExceededMessage: "Oh no! You have exceeded your number of download credits. Please contact your sales rep for more.",

        //Zapier
        isResellerPrimaryAccount: false,
        isResellerSubaccount: false,
        isZapierSetupWarningPopup: false,
        zapierPopupTitle: '',
        zapierSetupMessage: ''
    }),
    methods: {
        //Get download contacts limit to show on top button on load
        getContactLimitsOnLoad() {
            // this.$vs.loading();
            this.axios.get("/ws/FindAnyoneSearchContacts/GetContactLimits").then((response) => {
                let data = response.data;
                this.totalContactLimitRemaining = data.TotalContactLimitRemaining
                    // this.$vs.loading.close();
            }).catch((e) => {
                // this.$vs.loading.close();
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
                this.showError(e);
            });
        },
        //Get download contacts limit to check when exporting
        async getContactLimits(exportType) {
            let contactSelectedCheck = this.companyDetailPopup ? this.selectedInner : this.selectedMain;
            if (contactSelectedCheck.length) {
                this.exportContactType = exportType;
                this.$vs.loading();
                await this.axios.get("/ws/FindAnyoneSearchContacts/GetContactLimits").then((response) => {
                    let data = response.data;
                    let selectedContacts = []
                    if (this.companyDetailPopup) {
                        selectedContacts = this.selectedInner
                    } else {
                        selectedContacts = this.selectedMain
                    }
                    if (this.addabelContactsCount > data.TotalContactLimitRemaining) {
                        this.isExceededDownloadLimit = true;
                        if (this.companyDetailPopup) {
                            this.selectedInner = [];
                        } else {
                            this.selectedMain = [];
                        }
                    } else {
                        if (data.DailyContactLimit == -1) {
                            //this.exportContacts(selectedContacts)
                            this.checkCreditDeductForExport(selectedContacts);
                        } else if (selectedContacts.length <= data.TotalContactLimitRemaining && selectedContacts.length <= data.DailyContactLimitRemaining) {
                            this.openContactCreditPopup()
                        } else {
                            this.creditLimitWarningPopup = true
                        }
                    }
                    this.$vs.loading.close();
                }).catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
            } else {
                this.selecteContactWarningForAddToMySavedPopupMessage = "Please select contact to export"
                this.selecteContactWarningForAddToMySavedPopup = true;
            }
        },
        //Search contacts based on search criteria
        searchContacts() {
            this.checkIsSearchQuery = [];
            //Job Criteria
            let isJobTitleEntered = false;
            let jobTitleEnteredParam = [];
            if (this.jobTitleEntered.length > 0) {
                isJobTitleEntered = true;
                for (let i = 0; i < this.jobTitleEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.jobTitleEntered[i]);
                    jobTitleEnteredParam.push({
                        Text: this.jobTitleEntered[i],
                    });
                }
            }
            let isJobLevelSelected = false;
            let jobLevelSelectedParam = [];
            if (this.jobLevelSelected.length > 0) {
                isJobLevelSelected = true;
                for (let i = 0; i < this.jobLevelSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.jobLevelSelected[i].Key);
                    jobLevelSelectedParam.push({
                        Text: this.jobLevelSelected[i].Key,
                    });
                }
            }
            let isjobFunctionSelected = false;
            let jobFunctionSelectedParam = [];
            if (this.jobFunctionSelected.length > 0) {
                isjobFunctionSelected = true;
                for (let i = 0; i < this.jobFunctionSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.jobFunctionSelected[i].Key);
                    jobFunctionSelectedParam.push({
                        Text: this.jobFunctionSelected[i].Key,
                    });
                }
            }
            let isJobStartDateSelected = false;
            let jobStartDateSelectedParam = "";
            if (this.jobStartDateSelected) {
                isJobStartDateSelected = true;
                jobStartDateSelectedParam = this.jobStartDateSelected.Key;
                this.checkIsSearchQuery.push(this.jobStartDateSelected.Key);
            }
            let recentChangedJobParam = false;
            if (this.recentChangedJob) {
                recentChangedJobParam = this.recentChangedJob;
                this.checkIsSearchQuery.push(this.recentChangedJob);
            }
            //Company Criteria
            let isCompanyNameEntered = false;
            let companyNameEnteredParam = [];
            if (this.companyNameEntered.length > 0) {
                isCompanyNameEntered = true;
                for (let i = 0; i < this.companyNameEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.companyNameEntered[i]);
                    companyNameEnteredParam.push({
                        Text: this.companyNameEntered[i],
                    });
                }
            }
            let isCompanyWebAddressEntered = false;
            let companyWebAddressEnteredParam = [];
            if (this.companyWebAddressEntered.length > 0) {
                isCompanyWebAddressEntered = true;
                for (let i = 0; i < this.companyWebAddressEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.companyWebAddressEntered[i]);
                    companyWebAddressEnteredParam.push({
                        Text: this.companyWebAddressEntered[i],
                    });
                }
            }
            let isCompanyIndustrySelected = false;
            let companyIndustrySelectedParam = [];
            if (this.companyIndustrySelected.length > 0) {
                isCompanyIndustrySelected = true;
                for (let i = 0; i < this.companyIndustrySelected.length; i++) {
                    this.checkIsSearchQuery.push(this.companyIndustrySelected[i].Key);
                    companyIndustrySelectedParam.push({
                        Text: this.companyIndustrySelected[i].Key,
                    });
                }
            }
            let isCompanyEmployeeSizeSelected = false;
            let companyEmployeeSizeSelectedParam = [];
            if (this.companyEmployeeSizeSelected.length > 0) {
                isCompanyEmployeeSizeSelected = true;
                for (let i = 0; i < this.companyEmployeeSizeSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.companyEmployeeSizeSelected[i].Key);
                    companyEmployeeSizeSelectedParam.push({
                        Text: this.companyEmployeeSizeSelected[i].Key,
                    });
                }
            }
            let isCompanyRevenueBandsSelected = false;
            let companyRevenueBandsSelectedParam = [];
            if (this.companyRevenueBandsSelected.length > 0) {
                isCompanyRevenueBandsSelected = true;
                for (let i = 0; i < this.companyRevenueBandsSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.companyRevenueBandsSelected[i].Key);
                    companyRevenueBandsSelectedParam.push({
                        Text: this.companyRevenueBandsSelected[i].Key,
                    });
                }
            }
            let isCompanyNAICSSelected = false;
            let companyNAICSSelectedParam = [];
            if (this.companyNAICSSelected.length > 0) {
                isCompanyNAICSSelected = true;
                for (let i = 0; i < this.companyNAICSSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.companyNAICSSelected[i].Key);
                    companyNAICSSelectedParam.push({
                        Text: this.companyNAICSSelected[i].Key,
                    });
                }
            }
            let isCompanySICSSelected = false;
            let companySICSSelectedParam = [];
            if (this.companySICSSelected.length > 0) {
                isCompanySICSSelected = true;
                for (let i = 0; i < this.companySICSSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.companySICSSelected[i].Key);
                    companySICSSelectedParam.push({
                        Text: this.companySICSSelected[i].Key,
                    });
                }
            }
            //Location Criteria
            let isContactRegionSelected = false;
            let contactRegionSelectedParam = [];
            if (this.contactRegionSelected.length > 0) {
                isContactRegionSelected = true;
                for (let i = 0; i < this.contactRegionSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.contactRegionSelected[i].Key);
                    contactRegionSelectedParam.push({
                        Text: this.contactRegionSelected[i].Key,
                    });
                }
            }
            let isAddressTypeSelected = false;
            let isAddressTypeSelectedSelectedParam = [];
            if (this.addressTypeSelected.length > 0) {
                isAddressTypeSelected = true;
                for (let i = 0; i < this.addressTypeSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.addressTypeSelected[i].Key);
                    isAddressTypeSelectedSelectedParam.push({
                        Text: this.addressTypeSelected[i].Key,
                    });
                }
            }
            let isContactCountriesSelected = false;
            let contactCountriesSelectedParam = [];
            if (this.contactCountriesSelected.length > 0) {
                isContactCountriesSelected = true;
                for (let i = 0; i < this.contactCountriesSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.contactCountriesSelected[i].Key);
                    contactCountriesSelectedParam.push({
                        Text: this.contactCountriesSelected[i].Key,
                    });
                }
            }
            let isContactStatesSelected = false;
            let contactStatesSelectedParam = [];
            if (this.contactStatesSelected.length > 0) {
                isContactStatesSelected = true;
                for (let i = 0; i < this.contactStatesSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.contactStatesSelected[i].Key);
                    contactStatesSelectedParam.push({
                        Text: this.contactStatesSelected[i].Key,
                    });
                }
            }
            let isContactCityEntered = false;
            let contactCityEnteredParam = [];
            if (this.contactCityEntered.length > 0) {
                isContactCityEntered = true;
                for (let i = 0; i < this.contactCityEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.contactCityEntered[i]);
                    contactCityEnteredParam.push({
                        Text: this.contactCityEntered[i],
                    });
                }
            }
            let isContactZipCodeEntered = false;
            let contactZipCodeEnteredParam = [];
            if (this.contactZipCodeEntered.length > 0) {
                isContactZipCodeEntered = true;
                for (let i = 0; i < this.contactZipCodeEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.contactZipCodeEntered[i]);
                    contactZipCodeEnteredParam.push({
                        Text: this.contactZipCodeEntered[i],
                    });
                }
            }
            // let localAddressRequiredParam = false;
            // if (this.localAddressRequired) {
            //   localAddressRequiredParam = this.localAddressRequired;
            // }
            //Personal Criteria
            let isFullNameEntered = false;
            let personalContactFirstNameEnteredParam = "";
            let personalContactLastNameEnteredParam = "";
            if (
                this.personalContactFirstNameEntered ||
                this.personalContactLastNameEntered
            ) {
                isFullNameEntered = true;
                personalContactFirstNameEnteredParam = this
                    .personalContactFirstNameEntered;
                personalContactLastNameEnteredParam = this
                    .personalContactLastNameEntered;
                this.checkIsSearchQuery.push(this.personalContactFirstNameEntered);
                this.checkIsSearchQuery.push(this.personalContactFirstNameEntered);
            }
            let isPersonalContactGenderSelected = false;
            let personalContactGenderSelectedParam = [];
            if (this.personalContactGenderSelected.length > 0) {
                isPersonalContactGenderSelected = true;
                for (let i = 0; i < this.personalContactGenderSelected.length; i++) {
                    this.checkIsSearchQuery.push(this.personalContactGenderSelected[i].Key);
                    personalContactGenderSelectedParam.push({
                        Text: this.personalContactGenderSelected[i].Key,
                    });
                }
            }
            let isPersonalContactAlmaMaterEntered = false;
            let personalContactAlmaMaterEnteredParam = [];
            if (this.personalContactAlmaMaterEntered.length > 0) {
                isPersonalContactAlmaMaterEntered = true;
                for (let i = 0; i < this.personalContactAlmaMaterEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.personalContactAlmaMaterEntered[i]);
                    personalContactAlmaMaterEnteredParam.push({
                        Text: this.personalContactAlmaMaterEntered[i],
                    });
                }
            }
            let isBirthDateEntered = false;
            let personalContactBirthdayFromEnteredParam = 0;
            let personalContactBirthdayToEnteredParam = 0;
            if (
                this.personalContactBirthdayFromEntered ||
                this.personalContactBirthdayToEntered
            ) {
                isBirthDateEntered = true;
                personalContactBirthdayFromEnteredParam = this
                    .personalContactBirthdayFromEntered;
                personalContactBirthdayToEnteredParam = this
                    .personalContactBirthdayToEntered;
                this.checkIsSearchQuery.push(this.personalContactBirthdayFromEntered);
                this.checkIsSearchQuery.push(this.personalContactBirthdayToEntered);
            }
            let isPersonalContactFormerEmployerEntered = false;
            let personalContactFormerEmployerEnteredParam = [];
            if (this.personalContactFormerEmployerEntered.length > 0) {
                isPersonalContactFormerEmployerEntered = true;
                for (
                    let i = 0; i < this.personalContactFormerEmployerEntered.length; i++
                ) {
                    this.checkIsSearchQuery.push(this.personalContactFormerEmployerEntered[i]);
                    personalContactFormerEmployerEnteredParam.push({
                        Text: this.personalContactFormerEmployerEntered[i],
                    });
                }
            }
            let isPersonalContactSkillsEntered = false;
            let personalContactSkillsEnteredParam = [];
            if (this.personalContactSkillsEntered.length > 0) {
                isPersonalContactSkillsEntered = true;
                for (let i = 0; i < this.personalContactSkillsEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.personalContactSkillsEntered[i]);
                    personalContactSkillsEnteredParam.push({
                        Text: this.personalContactSkillsEntered[i],
                    });
                }
            }
            // let isPersonalContactMinimumConnectionEntered = false;
            // let personalContactMinimumConnectionEnteredParam = [];
            // if (this.personalContactMinimumConnectionEntered) {
            //   isPersonalContactMinimumConnectionEntered = true;
            //   personalContactMinimumConnectionEnteredParam = this
            //     .personalContactMinimumConnectionEntered;
            // }
            let personalContactEmailAddressRequiredParam = false;
            if (this.personalContactEmailAddressRequired) {
                personalContactEmailAddressRequiredParam = this
                    .personalContactEmailAddressRequired;
                this.checkIsSearchQuery.push(this.personalContactEmailAddressRequired);
            }
            let personalContactPhoneRequiredParam = false;
            if (this.personalContactPhoneRequired) {
                personalContactPhoneRequiredParam = this.personalContactPhoneRequired;
                this.checkIsSearchQuery.push(this.personalContactPhoneRequired);
            }
            //Suppression Criteria
            // let isSuppressionLastUpdatedDateSelected = false;
            // let suppressionLastUpdatedDateSelectedParam = "";
            // if (this.suppressionLastUpdatedDateSelected) {
            //   isSuppressionLastUpdatedDateSelected = true;
            //   suppressionLastUpdatedDateSelectedParam = this
            //     .suppressionLastUpdatedDateSelected.Key;
            // }
            let isSuppressionCompanyWebAddressEntered = false;
            let suppressionCompanyWebAddressEnteredParam = [];
            if (this.suppressionCompanyWebAddressEntered.length > 0) {
                isSuppressionCompanyWebAddressEntered = true;
                for (
                    let i = 0; i < this.suppressionCompanyWebAddressEntered.length; i++
                ) {
                    this.checkIsSearchQuery.push(this.suppressionCompanyWebAddressEntered[i]);
                    suppressionCompanyWebAddressEnteredParam.push({
                        Text: this.suppressionCompanyWebAddressEntered[i],
                    });
                }
            }
            let isSuppressionContactTitlesEntered = false;
            let suppressionContactTitlesEnteredParam = [];
            if (this.suppressionContactTitlesEntered.length > 0) {
                isSuppressionContactTitlesEntered = true;
                for (let i = 0; i < this.suppressionContactTitlesEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.suppressionContactTitlesEntered[i]);
                    suppressionContactTitlesEnteredParam.push({
                        Text: this.suppressionContactTitlesEntered[i],
                    });
                }
            }
            let isSuppressionEmailAddressEntered = false;
            let suppressionEmailAddressEnteredParam = [];
            if (this.suppressionEmailAddressEntered.length > 0) {
                isSuppressionEmailAddressEntered = true;
                for (let i = 0; i < this.suppressionEmailAddressEntered.length; i++) {
                    this.checkIsSearchQuery.push(this.suppressionEmailAddressEntered[i]);
                    suppressionEmailAddressEnteredParam.push({
                        Text: this.suppressionEmailAddressEntered[i],
                    });
                }
            }
            let suppressionExcludeMyLeadsParam = false;
            if (this.suppressionExcludeMyLeads) {
                this.checkIsSearchQuery.push(this.suppressionExcludeMyLeads);
                suppressionExcludeMyLeadsParam = this.suppressionExcludeMyLeads;
            }
            //List Criteria
            let listPrimaryContactParam = false;
            if (this.listPrimaryContact) {
                this.checkIsSearchQuery.push(this.listPrimaryContact);
                listPrimaryContactParam = this.listPrimaryContact;
            }
            let listProfessionalEmailParam = false;
            if (this.listProfessionalEmail) {
                this.checkIsSearchQuery.push(this.listProfessionalEmail);
                listProfessionalEmailParam = this.listProfessionalEmail;
            }
            let listEmailConfidenceScoreParam = this.listEmailConfidenceScore;
            let listDeterministicEmailParam = false;
            if (this.listDeterministicEmail) {
                this.checkIsSearchQuery.push(this.listDeterministicEmail);
                listDeterministicEmailParam = this.listDeterministicEmail;
            }
            let listPhoneNumberParam = false;
            if (this.listPhoneNumber) {
                this.checkIsSearchQuery.push(this.listPhoneNumber);
                listPhoneNumberParam = this.listPhoneNumber;
            }

            this.searchContactsParams = {
                IsContactTitlesSelected: isJobTitleEntered,
                ContactTitles: jobTitleEnteredParam,
                IsContactLevelsSelected: isJobLevelSelected,
                ContactLevels: jobLevelSelectedParam,
                IsContactJobFunctionsSelected: isjobFunctionSelected,
                ContactJobFunctions: jobFunctionSelectedParam,
                IsContactJobStartDateSelected: isJobStartDateSelected,
                ContactJobStartDate: jobStartDateSelectedParam,
                HasChangedJobsRecently: recentChangedJobParam,

                IsCompanyNamesSelected: isCompanyNameEntered,
                CompanyNames: companyNameEnteredParam,
                IsURLsSelected: isCompanyWebAddressEntered,
                Urls: companyWebAddressEnteredParam,
                IsCompanyIndustriesSelected: isCompanyIndustrySelected,
                CompanyIndustries: companyIndustrySelectedParam,
                IsCompanySizesSelected: isCompanyEmployeeSizeSelected,
                CompanySizeIds: companyEmployeeSizeSelectedParam,
                IsCompanyRevenuesSelected: isCompanyRevenueBandsSelected,
                CompanyRevenueIds: companyRevenueBandsSelectedParam,
                IsCompanyNAICSCodesSelected: isCompanyNAICSSelected,
                CompanyNAICSCodes: companyNAICSSelectedParam,
                IsCompanySICCodesSelected: isCompanySICSSelected,
                CompanySICCodes: companySICSSelectedParam,

                IsRegionSelected: isContactRegionSelected,
                IsAddressTypeSelected: isAddressTypeSelected,
                Regions: contactRegionSelectedParam,
                AddressTypes: isAddressTypeSelectedSelectedParam,
                IsCountrySelected: isContactCountriesSelected,
                Countries: contactCountriesSelectedParam,
                IsStatesSelected: isContactStatesSelected,
                States: contactStatesSelectedParam,
                IsCitiesSelected: isContactCityEntered,
                Cities: contactCityEnteredParam,
                IsZipCodesSelected: isContactZipCodeEntered,
                ZipCodes: contactZipCodeEnteredParam,
                // HasLocalAddress: localAddressRequiredParam,

                IsFullNameSelected: isFullNameEntered,
                FirstName: personalContactFirstNameEnteredParam,
                LastName: personalContactLastNameEnteredParam,
                IsGenderSelected: isPersonalContactGenderSelected,
                Gender: personalContactGenderSelectedParam,
                IsAlmaMaterSelected: isPersonalContactAlmaMaterEntered,
                AlmaMater: personalContactAlmaMaterEnteredParam,
                IsBirthDateSelected: isBirthDateEntered,
                FromAge: personalContactBirthdayFromEnteredParam,
                ToAge: personalContactBirthdayToEnteredParam,
                IsContactSkillsSelected: isPersonalContactSkillsEntered,
                ContactSkills: personalContactSkillsEnteredParam,
                IsFormerEmployerSelected: isPersonalContactFormerEmployerEntered,
                FormerEmployer: personalContactFormerEmployerEnteredParam,
                // IsNumberOfConnectionsSelected: isPersonalContactMinimumConnectionEntered,
                // NumberOfConnections: personalContactMinimumConnectionEnteredParam,
                PersonalEmailOnly: personalContactEmailAddressRequiredParam,
                IsCellPhoneRequired: personalContactPhoneRequiredParam,

                // IsLastUpdatedSelected: isSuppressionLastUpdatedDateSelected,
                // LastUpdatedInMonths: suppressionLastUpdatedDateSelectedParam,
                IsUrlsToSuppressSelected: isSuppressionCompanyWebAddressEntered,
                UrlsToSuppress: suppressionCompanyWebAddressEnteredParam,
                IsTitlesToSuppressSelected: isSuppressionContactTitlesEntered,
                TitlesToSuppress: suppressionContactTitlesEnteredParam,
                IsEmailsToSuppressSelected: isSuppressionEmailAddressEntered,
                EmailsToSuppress: suppressionEmailAddressEnteredParam,
                IsExcludeMyLeadsSelected: suppressionExcludeMyLeadsParam,

                PrimaryContactsOnly: listPrimaryContactParam,
                IsProfessionalEmailRequired: listProfessionalEmailParam,
                DeterministicEmailsOnly: listDeterministicEmailParam,
                HasPhone: listPhoneNumberParam,

                IsEmailConfidenceSelected: this.$refs.collapseAccordion.showListEmailConfidenceScore,
                EmailConfidenceScore: listEmailConfidenceScoreParam,

                DirectDialOnly: false,
                IsEmailAddressSelected: false,
                IsUseEmailSuppressionFileSelected: false,
                IsUseTitleSuppressionFileSelected: false,
                IsUseUrlSuppressionFileSelected: false,
                IsLimitPerCompany: true,
                IsLimitPerListSelected: true,
                LimitPerCompanyCount: 0,
                LimitPerList: 0,
                Page: 1,
                PageSize: this.recordPerMainPage,
                RequiredEmail: true,
                EmailAddress: [],
                UseAllSuppressionRecords: true,
                ValidateEmails: true,
            };
            if (this.contacts.length > 0) {
                this.$refs.searchContactsMainPaginate.currentPage = 1
            }
            this.$vs.loading();
            this.axios.post("/ws/FindAnyoneSearchContacts/SearchContacts", this.searchContactsParams).then((response) => {
                    let data = response.data;
                    if (data.Contacts) {
                        this.contacts = data.Contacts;
                    } else {
                        this.contacts = [];
                    }
                    this.totalContacts = data.ContactCount;
                    this.totalPages = Math.ceil(data.ContactCount / data.ContactsPerPage);
                    if (data.ContactCount >= 10000) {
                        this.totalPages = 999
                    }
                    let that = this;
                    this.contacts.map(function(manipulatedData) {
                        manipulatedData.location = that.beautifyAddress(manipulatedData);
                        manipulatedData.addressHq = that.beautifyAddress3(manipulatedData, 'hq')
                        manipulatedData.addressLocal = that.beautifyAddress3(manipulatedData, 'local')
                        manipulatedData.addressPhysical = that.beautifyAddress3(manipulatedData, 'physical')
                        manipulatedData.fullName =
                            manipulatedData.ContactFirstName +
                            " " +
                            manipulatedData.ContactLastName;
                        return manipulatedData;
                    });
                    this.manipulateLocationCoulumnHeading()
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        //Search criteria based on criteria via Pagination
        searchContactsByPagination() {
            let pageNumber;
            if (!this.companyDetailPopup) {
                pageNumber = this.$refs.searchContactsMainPaginate.currentPage;
            } else {
                pageNumber = this.$refs.searchContactsRef.currentPage;
            }
            this.searchContactsParams.Page = pageNumber;
            this.searchContactsParams.PageSize = this.recordPerMainPage
            this.$vs.loading();
            this.axios
                .post(
                    "/ws/FindAnyoneSearchContacts/SearchContacts",
                    this.searchContactsParams
                )
                .then((response) => {
                    let data = response.data;
                    if (data.Contacts) {
                        this.contacts = data.Contacts;
                    } else {
                        this.contacts = [];
                    }
                    this.totalContacts = data.ContactCount;
                    this.totalPages = Math.ceil(data.ContactCount / data.ContactsPerPage);
                    if (data.ContactCount >= 10000) {
                        this.totalPages = 999
                    }
                    let that = this;
                    this.contacts.map(function(manipulatedData) {
                        manipulatedData.location = that.beautifyAddress(manipulatedData);
                        manipulatedData.addressHq = that.beautifyAddress3(manipulatedData, 'hq')
                        manipulatedData.addressLocal = that.beautifyAddress3(manipulatedData, 'local')
                        manipulatedData.addressPhysical = that.beautifyAddress3(manipulatedData, 'physical')
                        manipulatedData.fullName =
                            manipulatedData.ContactFirstName +
                            " " +
                            manipulatedData.ContactLastName;
                        return manipulatedData;
                    });
                    this.manipulateLocationCoulumnHeading()
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                });
        },
        //Get saved contact details without using credit
        getSavedContactDetail() {
            this.creditWarningPopup = false;
            this.numberOfCreditMessage = null;
            this.isCreditDeductionEnable = false;
            this.contactsDetailPopup = true;
            this.isContactDetailsPopupForSaved = true;
            this.$vs.loading();
            this.axios
                .get(
                    "/ws/FindAnyoneSavedContacts/GetSavedContactDetail?id=" +
                    this.selectedContactId
                )
                .then((response) => {
                    let data = response.data;
                    this.contactDetails = data;
                    let officeAddressArr = [];
                    let contactAddressArr = [];
                    let physicalAddress = [];
                    officeAddressArr.push(this.contactDetails.ContactOfficeAddress);
                    officeAddressArr.push(this.contactDetails.ContactOfficeCity);
                    officeAddressArr.push(this.contactDetails.ContactOfficeState);
                    officeAddressArr.push(this.contactDetails.ContactOfficeCountry);
                    contactAddressArr.push(this.contactDetails.CompanyAddressLine1);
                    contactAddressArr.push(this.contactDetails.CompanyAddressLine2);
                    contactAddressArr.push(this.contactDetails.CompanyCity);
                    contactAddressArr.push(this.contactDetails.CompanyRegion);
                    contactAddressArr.push(this.contactDetails.CompanyCountry);
                    physicalAddress.push(this.contactDetails.ContactPhysicalCity);
                    physicalAddress.push(this.contactDetails.ContactPhysicalState);
                    physicalAddress.push(this.contactDetails.ContactPhysicalCountry);
                    let officeAddressFinal = "";
                    let contactAddressFinal = "";
                    let physicalAddressFinal = "";
                    for (let i = 0; i < officeAddressArr.length; i++) {
                        if (officeAddressArr[i] && officeAddressArr[i].length > 0) {
                            officeAddressFinal += officeAddressArr[i] + ", ";
                        }
                    }
                    for (let i = 0; i < contactAddressArr.length; i++) {
                        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
                            contactAddressFinal += contactAddressArr[i] + ", ";
                        }
                    }
                    for (let i = 0; i < physicalAddress.length; i++) {
                        if (physicalAddress[i] && physicalAddress[i].length > 0) {
                            physicalAddressFinal += physicalAddress[i] + ", ";
                        }
                    }
                    this.contactDetails.officeAddress = officeAddressFinal.substring(
                        0,
                        officeAddressFinal.length - 2
                    );
                    this.contactDetails.contactAddress = contactAddressFinal.substring(
                        0,
                        contactAddressFinal.length - 2
                    );
                    this.contactDetails.physicalAddress = physicalAddressFinal.substring(
                        0,
                        physicalAddressFinal.length - 2
                    );
                    this.listContactSavedTags();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        //close contact details popup
        closeContactDetailsPopup() {
            this.contactsDetailPopup = false;
            this.selectedContactId = null;
            this.contactDetails = null;
            // if (!this.isContactDetailsPopupForSaved) {
            //   this.searchPaginationSetTimeOut();
            //   if (this.companyDetailPopup) {
            //     this.$refs.searchContactsRef.searchPaginationSetTimeOut();
            //   }
            // }
            if (this.companyDetailPopup) {
                this.selectedInner = [];
            } else {
                this.selectedMain = [];
            }
            this.isContactDetailsPopupForSaved = false;
            this.getContactLimitsOnLoad()
        },
        //Open credit deduction message popup
        openContactCreditPopup() {
            let selectedContacts = []
            if (this.selectedContactId && !this.exportContactType) {
                this.numberOfCreditMessage = "This will deduct 1 credit";
                this.isCreditDeductionEnable = true;
            } else {
                if (this.companyDetailPopup) {
                    selectedContacts = this.selectedInner
                } else {
                    selectedContacts = this.selectedMain
                }
                if (selectedContacts.length > 0) {
                    this.isCreditDeductionEnable = true;
                    this.numberOfCreditMessage =
                        "This will deduct " + selectedContacts.length + " credits";
                } else {
                    this.isCreditDeductionEnable = false;
                }
            }
            this.creditWarningPopup = true;
        },
        //Get contacts for download as CSV or EXCEL
        async exportContacts(selectedContacts) {
            let exportContactsParams = []
            for (let i = 0; i < selectedContacts.length; i++) {
                exportContactsParams.push(selectedContacts[i].Id)
            }
            this.creditWarningPopup = false
            this.numberOfCreditMessage = null
            this.isCreditDeductionEnable = false
            this.selectedMain = []
            this.selectedInner = []
            this.$vs.loading();
            return await this.axios.post("/ws/FindAnyoneSearchContacts/ExportContacts", { contactIds: exportContactsParams }).then((response) => {
                let data = response.data;
                //this.downloadExcelContactPopup = true
                setTimeout(() => {
                    if (this.exportContactType == 'csv') {
                        this.$refs.selectedToCsv.click();
                    } else {
                        this.$refs.selectedToExcel.click();
                    }
                }, 500);
                this.downloadContacts = [{ name: "Contacts", data: data }]
                this.getContactLimitsOnLoad()
                this.searchPaginationSetTimeOut();
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
            });
        },
        //Get non saved contact details with using credit
        getContactDetail() {
            this.contactsDetailPopup = true;
            this.$vs.loading();
            this.axios
                .get(
                    "/ws/FindAnyoneSearchContacts/GetContactDetail?id=" +
                    this.selectedContactId
                )
                .then((response) => {
                    let data = response.data;
                    this.contactDetails = data;
                    let officeAddressArr = [];
                    let contactAddressArr = [];
                    officeAddressArr.push(this.contactDetails.ContactOfficeAddress);
                    officeAddressArr.push(this.contactDetails.ContactOfficeCity);
                    officeAddressArr.push(this.contactDetails.ContactOfficeState);
                    officeAddressArr.push(this.contactDetails.ContactOfficeCountry);
                    contactAddressArr.push(this.contactDetails.CompanyAddressLine1);
                    contactAddressArr.push(this.contactDetails.CompanyAddressLine2);
                    contactAddressArr.push(this.contactDetails.CompanyCity);
                    contactAddressArr.push(this.contactDetails.CompanyRegion);
                    contactAddressArr.push(this.contactDetails.CompanyCountry);
                    let officeAddressFinal = "";
                    let contactAddressFinal = "";
                    for (let i = 0; i < officeAddressArr.length; i++) {
                        if (officeAddressArr[i] && officeAddressArr[i].length > 0) {
                            officeAddressFinal += officeAddressArr[i] + ", ";
                        }
                    }
                    for (let i = 0; i < contactAddressArr.length; i++) {
                        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
                            contactAddressFinal += contactAddressArr[i] + ", ";
                        }
                    }
                    this.contactDetails.officeAddress = officeAddressFinal.substring(
                        0,
                        officeAddressFinal.length - 2
                    );
                    this.contactDetails.contactAddress = contactAddressFinal.substring(
                        0,
                        contactAddressFinal.length - 2
                    );
                    this.listContactSavedTags();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        //Get company details
        getCompanyDetail(id) {
            this.companySearchContactsParams = {
                CompanyId: id,
                Page: 1,
                PageSize: this.recordPerInnerPage,
            };
            this.$vs.loading();
            this.axios
                .post(
                    "/ws/FindAnyoneSearchContacts/GetCompanyDetail",
                    this.companySearchContactsParams
                )
                .then((response) => {
                    let data = response.data;
                    this.companyDetail = data;
                    this.companyTotalContacts = data.ContactSummary.ContactCount;
                    this.companyTotalPages = Math.ceil(
                        data.ContactSummary.ContactCount /
                        data.ContactSummary.ContactsPerPage
                    );
                    if (data.ContactSummary.ContactCount >= 10000) {
                        this.companyTotalPages = 999
                    }
                    let companyAddressArr = [];
                    companyAddressArr.push(this.companyDetail.CompanyAddressLine1);
                    companyAddressArr.push(this.companyDetail.CompanyAddressLine2);
                    companyAddressArr.push(this.companyDetail.CompanyCity);
                    companyAddressArr.push(this.companyDetail.CompanyRegion);
                    companyAddressArr.push(this.companyDetail.CompanyCountry);
                    let companyAddressFinal = "";
                    for (let i = 0; i < companyAddressArr.length; i++) {
                        if (companyAddressArr[i] && companyAddressArr[i].length > 0) {
                            companyAddressFinal += companyAddressArr[i] + ", ";
                        }
                    }

                    this.companyDetail.companyAddress = companyAddressFinal.substring(
                        0,
                        companyAddressFinal.length - 2
                    );
                    let that = this;
                    this.companyDetail.ContactSummary.Contacts.map(function(
                        manipulatedData
                    ) {
                        manipulatedData.location = that.beautifyAddress(manipulatedData);
                        manipulatedData.fullName =
                            manipulatedData.ContactFirstName +
                            " " +
                            manipulatedData.ContactLastName;
                        return manipulatedData;
                    });
                    this.$refs.searchContactsRef.searchContacts();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                });
        },
        //Get company details to paginate the contacts
        getCompanyDetailByPagination(pageNumber) {
            this.companySearchContactsParams.Page = pageNumber;
            this.companySearchContactsParams.PageSize = this.recordPerInnerPage
            this.$vs.loading();
            this.axios
                .post(
                    "/ws/FindAnyoneSearchContacts/GetCompanyDetail",
                    this.companySearchContactsParams
                )
                .then((response) => {
                    let data = response.data;
                    this.companyDetail = data;
                    this.companyTotalContacts = data.ContactSummary.ContactCount;
                    this.companyTotalPages = Math.ceil(
                        data.ContactSummary.ContactCount /
                        data.ContactSummary.ContactsPerPage
                    );
                    if (data.ContactSummary.ContactCount >= 10000) {
                        this.companyTotalPages = 999
                    }
                    let that = this;
                    this.companyDetail.ContactSummary.Contacts.map(function(
                        manipulatedData
                    ) {
                        manipulatedData.location = that.beautifyAddress(manipulatedData);
                        manipulatedData.fullName =
                            manipulatedData.ContactFirstName +
                            " " +
                            manipulatedData.ContactLastName;
                        return manipulatedData;
                    });
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                });
        },
        //close company details popup
        closeCompanyDetailPopup() {
            this.selectedInner = []
            this.companyDetailPopup = false;
            this.companyDetail = null;
            this.companySearchContactsParams = null;
            this.companyTotalContacts = 0;
            this.companyTotalPages = 0;
        },
        addToMySavedContacts() {
            if (!this.companyDetailPopup) {
                if (this.selectedMain.length > 0) {
                    for (let i = 0; i < this.selectedMain.length; i++) {
                        if (!this.selectedMain[i].IsSavedContact) {
                            this.addabelContactsCount++
                        }
                    }
                    if (this.addabelContactsCount > 0) {
                        this.selecteContactWarningForAddToMySavedPopup = false
                        this.selecteContactWarningForAddToMySavedPopupMessage = null
                        this.openAddToMySavedContactsCreditPopup = true
                        if (this.addabelContactsCount == 1) {
                            this.openAddToMySavedContactsCreditPopupMessage = "This will deduct 1 credit"
                        } else {
                            this.openAddToMySavedContactsCreditPopupMessage = "This will deduct " + this.addabelContactsCount + " credits"
                        }
                    } else {
                        this.selecteContactWarningForAddToMySavedPopup = true
                        this.selecteContactWarningForAddToMySavedPopupMessage = 'Please select not saved contacts to add as My Saved contacts.'
                    }
                } else {
                    this.selecteContactWarningForAddToMySavedPopup = true
                    this.selecteContactWarningForAddToMySavedPopupMessage = 'Please select contacts to add.'
                }
            } else {
                if (this.selectedInner.length > 0) {
                    for (let i = 0; i < this.selectedInner.length; i++) {
                        if (!this.selectedInner[i].IsSavedContact) {
                            this.addabelContactsCount++
                        }
                    }
                    if (this.addabelContactsCount > 0) {
                        this.selecteContactWarningForAddToMySavedPopup = false
                        this.selecteContactWarningForAddToMySavedPopupMessage = null
                        this.openAddToMySavedContactsCreditPopup = true
                        if (this.addabelContactsCount == 1) {
                            this.openAddToMySavedContactsCreditPopupMessage = "This will deduct 1 credit"
                        } else {
                            this.openAddToMySavedContactsCreditPopupMessage = "This will deduct " + this.addabelContactsCount + " credits"
                        }
                    } else {
                        this.selecteContactWarningForAddToMySavedPopup = true
                        this.selecteContactWarningForAddToMySavedPopupMessage = 'Please select not saved contacts to add as My Saved contacts.'
                    }
                } else {
                    this.selecteContactWarningForAddToMySavedPopup = true
                    this.selecteContactWarningForAddToMySavedPopupMessage = 'Please select contacts to add.'
                }
            }
        },
        cancelAddToMySavedContacts() {
            this.addabelContactsCount = 0
            this.openAddToMySavedContactsCreditPopup = false
            this.openAddToMySavedContactsCreditPopupMessage = null
            this.selectedMain = []
            this.selectedInner = []
        },
        getContactLimitsForAddToMySavedContacts() {
            this.$vs.loading();
            this.axios.get("/ws/FindAnyoneSearchContacts/GetContactLimits").then((response) => {
                let data = response.data;
                if (this.addabelContactsCount > data.TotalContactLimitRemaining) {
                    this.addabelContactsCount = 0
                    this.openAddToMySavedContactsCreditPopup = false
                    this.openAddToMySavedContactsCreditPopupMessage = null
                    if (this.companyDetailPopup) {
                        this.selectedInner = [];
                    } else {
                        this.selectedMain = [];
                    }
                    this.isExceededDownloadLimit = true;
                } else {
                    if (!this.companyDetailPopup) {
                        if (data.DailyContactLimit == -1) {
                            this.addabelContactsCount = 0
                            this.openAddToMySavedContactsCreditPopup = false
                            this.openAddToMySavedContactsCreditPopupMessage = null
                                // this.exportContactsForAddToMySavedContacts(this.selectedMain)
                            this.listContactSavedTags();
                            this.addTagsPopup = true;
                            this.tagModuleRequest = 'savecontact';
                        } else if (this.selectedMain.length <= data.TotalContactLimitRemaining && this.selectedMain.length <= data.DailyContactLimitRemaining) {
                            this.addabelContactsCount = 0
                            this.openAddToMySavedContactsCreditPopup = false
                            this.openAddToMySavedContactsCreditPopupMessage = null
                            this.listContactSavedTags();
                            this.addTagsPopup = true;
                            this.tagModuleRequest = 'savecontact';
                            // this.exportContactsForAddToMySavedContacts(this.selectedMain)
                        } else {
                            this.creditLimitWarningPopup = true
                        }
                    } else {
                        if (data.DailyContactLimit == -1) {
                            this.addabelContactsCount = 0
                            this.openAddToMySavedContactsCreditPopup = false
                            this.openAddToMySavedContactsCreditPopupMessage = null
                                //this.exportContactsForAddToMySavedContacts(this.selectedInner)
                            this.listContactSavedTags();
                            this.addTagsPopup = true;
                            this.tagModuleRequest = 'savecontact';
                        } else if (this.selectedInner.length <= data.TotalContactLimitRemaining && this.selectedInner.length <= data.DailyContactLimitRemaining) {
                            this.addabelContactsCount = 0
                            this.openAddToMySavedContactsCreditPopup = false
                            this.openAddToMySavedContactsCreditPopupMessage = null
                            this.listContactSavedTags();
                            this.addTagsPopup = true;
                            this.tagModuleRequest = 'savecontact';
                            //this.exportContactsForAddToMySavedContacts(this.selectedInner)
                        } else {
                            this.creditLimitWarningPopup = true
                        }
                    }
                }
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
                this.showError(e);
            });
        },
        exportContactsForAddToMySavedContacts(selectedContacts) {
            let exportContactsParams = []
            for (let i = 0; i < selectedContacts.length; i++) {
                exportContactsParams.push(selectedContacts[i].Id)
            }
            this.$vs.loading();
            this.axios.post("/ws/FindAnyoneSearchContacts/ExportContacts", { contactIds: exportContactsParams }).then(() => {
                this.cancelAddToMySavedContacts()
                this.addToMySavedContactsSuccessPopup = true
                this.getContactLimitsOnLoad()
                if (!this.companyDetailPopup) {
                    this.searchPaginationSetTimeOut();
                } else {
                    this.$refs.searchContactsRef.searchPaginationSetTimeOut();
                }
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
            });
        },
        checkCreditDeductForExport(selectedContacts) {
            let creditDeduct = selectedContacts.filter(contact => contact.IsSavedContact == false);
            if (creditDeduct.length > 0) {
                this.isCreditDeductionEnable = true;
                this.numberOfCreditMessage =
                    "This will deduct " + creditDeduct.length + " credits";
                this.creditWarningPopup = true;
            } else {
                //this.exportContacts(selectedContacts);
                this.listContactSavedTags();
                this.addTagsPopup = true;
                this.tagModuleRequest = 'export';
            }
        },
        addTagToContacts(paramsContactTag) {
            if(this.exportContactType == 'zap'){
                this.zapAddTagToContacts(paramsContactTag)
            }else{
                this.excelcsvAddTagToContacts(paramsContactTag)
            }
        },
        excelcsvAddTagToContacts(paramsContactTag){
            this.getContactLimitsOnLoad()
            if (this.totalContactLimitRemaining < 1) {
                this.isExceededDownloadLimit = true;
                if (this.companyDetailPopup) {
                    this.selectedInner = [];
                } else {
                    this.selectedMain = [];
                }
            } else {
                this.addTagsPopup = false;
                let contactTags = [];
                let contactIds = [];
                if (paramsContactTag.contactSelectedTags && paramsContactTag.contactSelectedTags.length > 0) {
                    paramsContactTag.contactSelectedTags.forEach(element => {
                        contactTags.push(element.TagName);
                    })
                }
                if (this.companyDetailPopup) {
                    this.selectedInner.forEach(element => {
                        contactIds.push(element.Id);
                    });
                } else {
                    this.selectedMain.forEach(element => {
                        contactIds.push(element.Id);
                    });
                }
                if (this.tagModuleRequest == 'contactdetails' && !contactIds.length) {
                    contactIds.push(this.selectedContactId);
                }
                let addContactParams = {
                    contactIds: contactIds,
                    ContactUserDefinedTags: contactTags,
                    IsExport: paramsContactTag.isExportContact,
                    ContactSavedFrom: this.tagModuleRequest == 'export' || this.tagModuleRequest == 'savecontact' || this.tagModuleRequest == 'contactdetails' ? 'searchcontact' : '',
                };
                this.$vs.loading();
                this.axios.post("/ws/FindAnyoneSearchContacts/ExportContacts", addContactParams).then((response) => {
                    let data = response.data;
                    this.$refs.addTagToSelectedContact.selectedTag = [];
                    this.addTagsPopup = false;
                    if (this.tagModuleRequest == 'export') {
                        data.forEach(element => {
                            element.ContactUserDefinedTags = element.ContactUserDefinedTags ? element.ContactUserDefinedTags.join() : '';
                        });
                        this.downloadContacts = [{ name: "Contacts", data: data }]
                        setTimeout(() => {
                            if (this.exportContactType == 'csv') {
                                this.$refs.selectedToCsv.click();
                            } else {
                                this.$refs.selectedToExcel.click();
                            }
                        }, 500);
                        this.getContactLimitsOnLoad()
                    }
                    if (this.tagModuleRequest == 'savecontact') {
                        this.getContactLimitsOnLoad()
                    }
                    if (this.companyDetailPopup) {
                        this.selectedInner = [];
                    } else {
                        this.selectedMain = [];
                    }
                    this.exportContactType = null;
                    if (this.tagModuleRequest == 'contactdetails') {
                        this.getContactLimitsOnLoad();
                        this.getContactDetail();
                    }
                    if (this.tagModuleRequest == 'savecontact') {
                        this.addToMySavedContactsSuccessPopup = true
                    }
                    // this.updateContactsList(data);
                    this.searchContacts();
                    this.$vs.loading.close();
                }).catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                });
            }

        },
        zapAddTagToContacts(paramsContactTag){
            this.getContactLimitsOnLoad()
            if (this.totalContactLimitRemaining < 1) {
                this.isExceededDownloadLimit = true;
                if (this.companyDetailPopup) {
                    this.selectedInner = [];
                } else {
                    this.selectedMain = [];
                }
            } else {
                this.addTagsPopup = false;
                let contactTags = [];
                let contactIds = [];
                if (paramsContactTag.contactSelectedTags && paramsContactTag.contactSelectedTags.length > 0) {
                    paramsContactTag.contactSelectedTags.forEach(element => {
                        contactTags.push(element.TagName);
                    })
                }
                if (this.companyDetailPopup) {
                    this.selectedInner.forEach(element => {
                        contactIds.push(element.Id);
                    });
                } else {
                    this.selectedMain.forEach(element => {
                        contactIds.push(element.Id);
                    });
                }
                if (this.tagModuleRequest == 'contactdetails' && !contactIds.length) {
                    contactIds.push(this.selectedContactId);
                }
                let addContactParams = {
                    contactIds: contactIds,
                    ContactUserDefinedTags: contactTags,
                    IsExport: paramsContactTag.isExportContact,
                    ContactSavedFrom: this.tagModuleRequest == 'export' || this.tagModuleRequest == 'savecontact' || this.tagModuleRequest == 'contactdetails' ? 'searchcontact' : '',
                };
                this.$vs.loading();
                this.axios.post("/ws/FindAnyoneSearchContacts/ExportContactsToZap", addContactParams).then(() => {
                    // let data = response.data;
                    this.$refs.addTagToSelectedContact.selectedTag = [];
                    this.addTagsPopup = false;
                    if (this.tagModuleRequest == 'export') {
                        this.getContactLimitsOnLoad()
                    }
                    if (this.tagModuleRequest == 'savecontact') {
                        this.getContactLimitsOnLoad()
                    }
                    if (this.companyDetailPopup) {
                        this.selectedInner = [];
                    } else {
                        this.selectedMain = [];
                    }
                    this.exportContactType = null;
                    if (this.tagModuleRequest == 'contactdetails') {
                        this.getContactLimitsOnLoad();
                        this.getContactDetail();
                    }
                    if (this.tagModuleRequest == 'savecontact') {
                        this.addToMySavedContactsSuccessPopup = true
                    }
                    this.isZapierSetupWarningPopup = true
                    this.zapierPopupTitle = 'Zapier Export'
                    this.zapierSetupMessage = 'The contacts have been added to the queue for email validation.  Once the contact has been validated it will be exported to Zapier.'
                    // this.updateContactsList(data);
                    this.searchContacts();
                    this.$vs.loading.close();
                }).catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                });
            }
        },
        listContactSavedTags(tagDetails = null) {
            this.$vs.loading();
            this.axios.get("/ws/FindAnyoneSearchContacts/ListContactSavedTagsUserDefined").then((response) => {
                let data = response.data;
                this.selectTagsOptions = data;
                if (this.addTagsPopup && tagDetails) {
                    let newTag = this.selectTagsOptions.filter((x) => (x.TagName) === tagDetails.tagName);
                    if (newTag.length) {
                        this.$refs.addTagToSelectedContact.selectedTag.push(newTag[0]);
                    }
                }
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
                this.showError(e);
            });
        },
        deleteUserDefinedTag(tagId) {
            this.$vs.loading();
            this.axios.get("/ws/FindAnyoneSearchContacts/DeleteContactSavedTagUserDefined?id=" + tagId).then(() => {
                this.listContactSavedTags();
                this.$vs.notify({
                    title: "Success",
                    text: 'Tag deleted successfully',
                    color: "success",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                });
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
                this.showError(e);
            });
        },
        async saveUpdateTag(tagDetails) {
            this.$vs.loading();
            let input = {
                "Id": tagDetails.tagId,
                "TagName": tagDetails.tagName,
                "IsShared": tagDetails.isTagShared
            }
            await this.axios
                .post("/ws/FindAnyoneSearchContacts/SaveContactSavedTagUserDefined", input)
                .then(() => {
                    this.$refs.addUpdateTag.newTagName = null;
                    this.$refs.addUpdateTag.isTagShared = false;
                    this.$refs.addUpdateTag.tagId = "";
                    this.userDefinedTagDetails = null;
                    this.addUpdateTagPopup = false;
                    this.listContactSavedTags(tagDetails);
                    this.$vs.notify({
                        title: "Success",
                        text: tagDetails.tagId ? 'Tag updated successfully' : 'Tag added successfully',
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000,
                    });
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Erorr",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        editUserDefinedTag(tagDetails) {
            this.userDefinedTagDetails = tagDetails;
            this.addUpdateTagPopup = true;
        },
        closeAddUpdateTagPopup() {
            this.userDefinedTagDetails = null;
            this.$refs.addUpdateTag.newTagName = null;
            this.$refs.addUpdateTag.isTagShared = false;
            this.$refs.addUpdateTag.tagId = "";
            this.addUpdateTagPopup = false;
        },
        addTagToSeleted() {
            let contactSelected = this.companyDetail ? this.selectedInner : this.selectedMain;
            if (contactSelected.length) {
                this.listContactSavedTags();
                this.addTagsPopup = true;
            } else {
                this.selecteContactWarningForAddToMySavedPopupMessage = "Please select contact to add tags to contact"
                this.selecteContactWarningForAddToMySavedPopup = true;
            }
        },
        removeTagSelected() {
            let contactSelected = this.companyDetail ? this.selectedInner : this.selectedMain;
            if (contactSelected.length) {
                this.addTagsPopup = true;
                this.listContactSavedTags();
            } else {
                this.selecteContactWarningForAddToMySavedPopupMessage = "Please select contact to renove tags from contact"
                this.selecteContactWarningForAddToMySavedPopup = true;
            }
        },
        removeTagsFromContactConfirmation(removeTags) {
            this.addTagsPopup = false;
            this.removeTagPopup = true;
            this.tagRemoveList = removeTags;
        },
        async removeTagsFromContact() {
            this.$vs.loading();
            let contactIds = [];
            let contactTags = [];
            if (this.companyDetailPopup) {
                this.selectedInner.forEach(element => {
                    contactIds.push(element.Id);
                });
            } else {
                this.selectedMain.forEach(element => {
                    contactIds.push(element.Id);
                });
            }
            if (this.tagRemoveList.length > 0) {
                this.tagRemoveList.forEach(element => {
                    contactTags.push(element.TagName);
                })
            }

            let input = {
                "ContactUserDefinedTags": contactTags,
                "ContactIds": contactIds
            }
            await this.axios
                .post("/ws/FindAnyoneSearchContacts/RemoveTagFromContacts", input)
                .then(() => {
                    this.removeTagPopup = false;
                    this.tagRemoveList = null;
                    this.$refs.addTagToSelectedContact.selectedTag = [];
                    if (this.companyDetailPopup) {
                        this.selectedInner = [];
                    } else {
                        this.selectedMain = [];
                    }
                    this.$vs.notify({
                        title: "Success",
                        text: 'Tags removed form contact successfully',
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000,
                    });
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Erorr",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        editTagsForContact() {
            this.addTagsPopup = true;
            if (this.contactDetails.ContactUserDefinedTags.length) {
                this.$refs.addTagToSelectedContact.selectedTag = [];
                this.selectTagsOptions.forEach(ele => {
                    if (this.contactDetails.ContactUserDefinedTags.includes(ele.TagName)) {
                        this.$refs.addTagToSelectedContact.selectedTag.push(ele);
                    }
                });
            }
        },
        updateTagsContactDetails(contactUserTags) {
            this.addTagsPopup = false;
            let contactTags = [];
            if (contactUserTags.length > 0) {
                contactUserTags.forEach(element => {
                    contactTags.push(element.TagName);
                })
            }
            let addContactParams = {
                Contact_Id: this.contactDetails.Id,
                Tag_Ids: contactTags,
            };
            this.$vs.loading();
            this.axios.post("/ws/FindAnyoneSavedContacts/UpdateSavedContactTags", addContactParams).then(() => {
                this.addTagsPopup = false;
                this.$refs.addTagToSelectedContact.selectedTag = [];
                this.getListOfTagsContactDetails();
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
            });
        },
        getListOfTagsContactDetails() {
            this.$vs.loading();
            this.axios.get("/ws/FindAnyoneSavedContacts/GetSavedContactTags?id=" + this.selectedContactId).then((response) => {
                    let data = response.data;
                    this.contactDetails.ContactUserDefinedTags = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Erorr",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        searchPaginationSetTimeOut() {
            setTimeout(() => {
                this.searchContactsByPagination();
            }, 1000);
        },
        updateContactsList(data) {
            let contact;
            if (this.companyDetailPopup) {
                contact = this.$refs.searchContactsRef.contacts;
            } else {
                contact = this.contacts;
            }
            let results;
            data.forEach(element => {
                results = contact.filter(ele => ele.Id == element.Id);
            });
            results.forEach(ele => {
                ele.IsSavedContact = true;
            });
        },
        savedContactQueryResultToCSV() {
            if (this.checkIsSearchQuery.length) {
                if (this.totalContacts == 0) {
                    this.exportJobRequestedMessage = true;
                    this.jobMessage = "There are no contacts to run query export to csv";
                } else if (this.totalContacts > this.totalContactLimitRemaining) {
                    this.exportJobRequestedMessage = true;
                    this.jobMessage = "You don't have enough credit to run the job.";
                } else {
                    //this.addJobNamePopup = true;
                    this.isCreditDeductionEnable = true;
                    this.numberOfCreditMessage =
                        "This may deduct up to " + this.totalContacts + " credits";
                    this.exportContactType = 'queryToCSV'
                    this.creditWarningPopup = true;
                }
            } else {
                this.exportJobRequestedMessage = true;
                this.jobMessage = "A search query must be ran before exporting a query result.";
            }
        },
        runDataEnrichmentJobQueryResultToCSV() {
            let queryParameter = {
                JobName: this.dataEnrichmentJobName,
                ContactUserDefinedTags: [],
                ContactSavedFrom: "searchcontact",
                IsExport: true,
                OnlySavedContacts: false,
                Search_Criteria: this.searchContactsParams,
            };
            this.$vs.loading();
            this.axios.post("/ws/DataEnrichmentAddJob/RunDataEnrichmentJobFromSearchQuery", queryParameter).then((response) => {
                    let data = response.data;
                    if (data.UploadCompleted) {
                        this.addJobNamePopup = false;
                        this.$refs.enrichmentJobName.jobName = '';
                        this.exportJobRequestedMessage = true;
                        this.jobMessage = "The export job was submitted and you will receive an email when the job is completed.";
                    } else {
                        if (data.UploadMessage == 'duplicate found') {
                            this.showJobNameErrorMessage = true;
                            this.jobNameErrorMessage = "The Job Title entered is already used.  Please choose a new name.";
                        } else {
                            this.addJobNamePopup = false;
                            this.$refs.enrichmentJobName.jobName = '';
                            this.$vs.notify({
                                title: "Error",
                                text: data.UploadMessage,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000,
                            });
                        }
                    }
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        closeDataEnrichmentJobNamePopup() {
            this.addJobNamePopup = false;
            this.$refs.enrichmentJobName.jobName = '';
        },
        checkHasZapierContactSetup(){
            this.$vs.loading();
            this.axios.get("/ws/FindAnyoneSearchContacts/CheckHasZapierContactSetup").then((response) => {
                let data = response.data;
                if(data == true){
                    this.getContactLimits('zap');
                }else{
                    if(this.isResellerPrimaryAccount || this.isResellerSubaccount){
                        this.isZapierSetupWarningPopup = true
                        this.zapierPopupTitle = 'Zapier Setup'
                        this.zapierSetupMessage = 'A Zap must be setup before using this function'
                    }else{
                        this.isZapierSetupWarningPopup = true
                        this.zapierPopupTitle = 'Zapier Setup'
                        this.zapierSetupMessage = 'A Zap must be setup before using this function.  Click <a href="https://support.visualvisitor.com/portal/en/kb/articles/sending-a-contact-to-zapier" target="_blank">here</a> To learn how to do this.'
                    }
                }
                this.$vs.loading.close();
            })
            .catch(e => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                    title: "Erorr",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000
                });
            });
        }
    },
}
