<template>
    <vs-popup :title="popupTitle" :active.sync="isPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full text-center">
            <b-card-text>
              <p>
                {{ downloadExceededMessage }}
              </p>
            </b-card-text>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          @click="$emit('clickForClose')"
          color="primary"
          type="filled"
          class="mr-2"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>
</template>

<script>

export default ({
    props: {
        popupTitle:{
            type: String,
            required: true,
        },
        isPopup:{
            type: Boolean,
            required: true,
        },
        downloadExceededMessage:{
            type: String,
            required: true,
        }
    }
})
</script>
