<template>
  <div>
    <!-- Clear Selected search criteria and Contacts,  Selected Search Criteria-->
    <vx-card class="mb-8">
      <div class="card-title more-card-title flex justify-between items-center">
        <div>
         <div class="flex items-center flex-wrap">
          <h2 class="w-auto mb-2"> {{ LabelConstant.headingLabelSelectedCriteria }} </h2>
          <p class="table-data-entries-details text-left mb-2"> {{ LabelConstant.buttonLabelTotalContacts }} :
            <span>{{ totalContacts | tableRecordNumberFormatter }}</span>
          </p>
          </div>
          <span class="text-success"> {{ LabelConstant.buttonLabelDownloadLimit }} :
            <b> {{  totalContactLimitRemaining | tableRecordNumberFormatter}}</b>
          </span>
        </div>
        <!-- Clear Selected search criteria and Contacts -->
        <div class="flex items-center flex-wrap">
          <a :href="chromeExtensionLink" target="_blank" v-if="!this.$store.state.isReseller">
            <vs-button type="filled" class="bg-actionbutton mr-2" >{{ LabelConstant.buttonLabelDownloadChromeExtension }}</vs-button>
          </a>
          <vs-button
            color="grey"
            type="border"
            class="grey-btn sm:mt-0 mt-2 ml-2"
            icon-pack="feather"
            icon="icon-refresh-ccw"
            @click="clearSearchCriteria()"
            >{{ LabelConstant.buttonLabelClearSearchCriteria }}
          </vs-button>
        </div>
      </div>
      <!-- Selected Search Criteria -->
      <div class="vx-row find-people">
        <div class="vx-col w-full">
          <input-tag v-model="tags" @input="testUpdateSelectedTags"></input-tag>
        </div>
      </div>
    </vx-card>
    <!-- Search Criteria Filter, Contacts Table -->
    <vx-card>
      <div class="vx-row find-people" >
        <!-- Search Criteria Filter -->
        <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full" id="v-step-0">
          <collapse-accordion
            ref="collapseAccordion"
            moduleName="SearchContact"
            class="search-filters"
            :jobTitleValue="jobTitleEntered"
            @jobTitleInput="
              (newJobTitle) => {
                jobTitleEntered = newJobTitle;
                termSeparation(jobTitleEntered,'jobTitle');
              }
            "
            jobLevelLabelName="Value"
            :jobLevelOptions="jobLevelOptions"
            :jobLevelValue="jobLevelSelected"
            @jobLevelInput="
              (newJobLevel) => {
                jobLevelSelected = newJobLevel;
              }
            "
            jobFunctionLabelName="Value"
            :jobFunctionOptions="jobFunctionOptions"
            :jobFunctionValue="jobFunctionSelected"
            @jobFunctionInput="
              (newJobFunction) => {
                jobFunctionSelected = newJobFunction;
              }
            "
            jobStartDateLabelName="Value"
            :jobStartDateOptions="jobStartDateOptions"
            :jobStartDateValue="jobStartDateSelected"
            @jobStartDateInput="
              (newJobStartDate) => {
                jobStartDateSelected = newJobStartDate;
              }
            "
            :recentChangedJobValue="recentChangedJob"
            @recentChangedJobInput="
              (newRecentChangedJob) => {
                recentChangedJob = newRecentChangedJob;
              }
            "
            :companyNameValue="companyNameEntered"
            @companyNameInput="
              (newCompanyName) => {
                companyNameEntered = newCompanyName;
                termSeparation(companyNameEntered,'companyName');
              }
            "
            :companyWebAddressValue="companyWebAddressEntered"
            @companyWebAddressInput="
              (newCompanyWebAddress) => {
                companyWebAddressEntered = newCompanyWebAddress;
                termSeparation(companyWebAddressEntered,'companyWebAddress');
              }
            "
            companyIndustryLabelName="Value"
            :companyIndustryOptions="companyIndustryOptions"
            :companyIndustryValue="companyIndustrySelected"
            @companyIndustryInput="
              (newCompanyIndustry) => {
                companyIndustrySelected = newCompanyIndustry;
              }
            "
            companyEmployeeSizeLabelName="Value"
            :companyEmployeeSizeOptions="companyEmployeeSizeOptions"
            :companyEmployeeSizeValue="companyEmployeeSizeSelected"
            @companyEmployeeSizeInput="
              (newCompanyEmployeeSize) => {
                companyEmployeeSizeSelected = newCompanyEmployeeSize;
              }
            "
            companyRevenueBandsLabelName="Value"
            :companyRevenueBandsOptions="companyRevenueBandsOptions"
            :companyRevenueBandsValue="companyRevenueBandsSelected"
            @companyRevenueBandsInput="
              (newCompanyRevenueBands) => {
                companyRevenueBandsSelected = newCompanyRevenueBands;
              }
            "
            companyNAICSLabelName="Value"
            :companyNAICSOptions="companyNAICSOptions"
            :companyNAICSValue="companyNAICSSelected"
            @companyNAICSInput="
              (newCompanyNAICS) => {
                companyNAICSSelected = newCompanyNAICS;
              }
            "
            companySICSLabelName="Value"
            :companySICSOptions="companySICSOptions"
            :companySICSValue="companySICSSelected"
            @companySICSInput="
              (newCompanyNAICS) => {
                companySICSSelected = newCompanyNAICS;
              }
            "
            :isRegion="false"
            contactRegionLabelName="Value"
            :contactRegionOptions="contactRegionOptions"
            :contactRegionValue="contactRegionSelected"
            @contactRegionInput="
              (newContactRegion) => {
                contactRegionSelected = newContactRegion;
              }
            "
            :isAddressType="true"
            addressTypeLabelName="Value"
            :addressTypeOptions="addressTypeOptions"
            :addressTypeValue="addressTypeSelected"
            @adddressTypeInput="
              (newAddressType) => {
                addressTypeSelected = newAddressType;
              }
            "
            contactCountriesLabelName="Value"
            :contactCountriesOptions="contactCountriesOptions"
            :contactCountriesValue="contactCountriesSelected"
            @contactCountriesInput="
              (newContactCountries) => {
                contactCountriesSelected = newContactCountries;
              }
            "
            contactStatesLabelName="Value"
            :contactStatesOptions="contactStatesOptions"
            :contactStatesValue="contactStatesSelected"
            @contactStatesInput="
              (newContactStates) => {
                contactStatesSelected = newContactStates;
              }
            "
            :contactCityValue="contactCityEntered"
            @contactCityInput="
              (newContactCity) => {
                contactCityEntered = newContactCity;
                termSeparation(contactCityEntered,'contactCity');
              }
            "
            :contactZipCodeValue="contactZipCodeEntered"
            @contactZipCodeInput="
              (newContactZipCode) => {
                contactZipCodeEntered = newContactZipCode;
                termSeparation(contactZipCodeEntered,'contactZipCode');
              }
            "
            :personalContactFirstNameValue="personalContactFirstNameEntered"
            @personalContactFirstNameInput="
              (newPersonalContactFirstName) => {
                personalContactFirstNameEntered = newPersonalContactFirstName;
              }
            "
            :personalContactLastNameValue="personalContactLastNameEntered"
            @personalContactLastNameInput="
              (newPersonalContactLastNameEntered) => {
                personalContactLastNameEntered = newPersonalContactLastNameEntered;
              }
            "
            personalContactGenderLabelName="Value"
            :personalContactGenderOptions="personalContactGenderOptions"
            :personalContactGenderValue="personalContactGenderSelected"
            @personalContactGenderInput="
              (newPersonalContactGender) => {
                personalContactGenderSelected = newPersonalContactGender;
              }
            "
            :personalContactAlmaMaterValue="personalContactAlmaMaterEntered"
            @personalContactAlmaMaterInput="
              (newPersonalContactAlmaMater) => {
                personalContactAlmaMaterEntered = newPersonalContactAlmaMater;
                termSeparation(personalContactAlmaMaterEntered,'personalContactAlmaMater');
              }
            "
            :personalContactBirthdayFromValue="
              personalContactBirthdayFromEntered
            "
            @personalContactBirthdayFromInput="
              (newPersonalContactBirthdayFrom) => {
                personalContactBirthdayFromEntered = newPersonalContactBirthdayFrom;
              }
            "
            :personalContactBirthdayToValue="personalContactBirthdayToEntered"
            @personalContactBirthdayToInput="
              (newPersonalContactBirthdayTo) => {
                personalContactBirthdayToEntered = newPersonalContactBirthdayTo;
              }
            "
            :personalContactSkillsValue="personalContactSkillsEntered"
            @personalContactSkillsInput="
              (newPersonalContactSkills) => {
                personalContactSkillsEntered = newPersonalContactSkills;
                termSeparation(personalContactSkillsEntered,'personalContactSkills');
              }
            "
            :personalContactFormerEmployerValue="
              personalContactFormerEmployerEntered
            "
            @personalContactFormerEmployerInput="
              (newPersonalContactFormerEmployer) => {
                personalContactFormerEmployerEntered = newPersonalContactFormerEmployer;
                termSeparation(personalContactFormerEmployerEntered,'personalContactFormerEmployer');
              }
            "
            :personalContactEmailAddressRequiredValue="
              personalContactEmailAddressRequired
            "
            @personalContactEmailAddressRequiredInput="
              (newPersonalContactEmailAddressRequired) => {
                personalContactEmailAddressRequired = newPersonalContactEmailAddressRequired;
              }
            "
            :personalContactPhoneRequiredValue="personalContactPhoneRequired"
            @personalContactPhoneRequiredInput="
              (newPersonalContactPhoneRequired) => {
                personalContactPhoneRequired = newPersonalContactPhoneRequired;
              }
            "
            :suppressionCompanyWebAddressValue="
              suppressionCompanyWebAddressEntered
            "
            @suppressionCompanyWebAddressInput="
              (newSuppressionCompanyWebAddress) => {
                suppressionCompanyWebAddressEntered = newSuppressionCompanyWebAddress;
                termSeparation(suppressionCompanyWebAddressEntered,'suppressionCompanyWebAddress');
              }
            "
            :suppressionContactTitlesValue="suppressionContactTitlesEntered"
            @suppressionContactTitlesInput="
              (newSuppressionContactTitles) => {
                suppressionContactTitlesEntered = newSuppressionContactTitles;
                termSeparation(suppressionContactTitlesEntered,'suppressionContactTitles');
              }
            "
            :suppressionEmailAddressValue="suppressionEmailAddressEntered"
            @suppressionEmailAddressInput="
              (newSuppressionEmailAddress) => {
                suppressionEmailAddressEntered = newSuppressionEmailAddress;
                termSeparation(suppressionEmailAddressEntered,'suppressionEmailAddress');
              }
            "
            :suppressionExcludeMyLeadsValue="suppressionExcludeMyLeads"
            @suppressionExcludeMyLeadsInput="
              (newSuppressionExcludeMyLeads) => {
                suppressionExcludeMyLeads = newSuppressionExcludeMyLeads;
              }
            "
            :listPrimaryContactValue="listPrimaryContact"
            @listPrimaryContactInput="
              (newListPrimaryContact) => {
                listPrimaryContact = newListPrimaryContact;
              }
            "
            :listProfessionalEmailValue="listProfessionalEmail"
            @listProfessionalEmailInput="
              (newListProfessionalEmail) => {
                listProfessionalEmail = newListProfessionalEmail;
              }
            "

            :showListEmailConfidenceScoreValue="showListEmailConfidenceScore"
            @showListEmailConfidenceScoreInput ="(newShowListEmailConfidenceScore) =>{
              showListEmailConfidenceScore = newShowListEmailConfidenceScore
            }"
            :listEmailConfidenceScoreValue="listEmailConfidenceScore"
            @listEmailConfidenceScoreInput ="(newListEmailConfidenceScore) =>{
              listEmailConfidenceScore = newListEmailConfidenceScore
            }"

            :listDeterministicEmailValue="listDeterministicEmail"
            @listDeterministicEmailInput="
              (newListDeterministicEmail) => {
                listDeterministicEmail = newListDeterministicEmail;
              }
            "
            :listPhoneNumberValue="listPhoneNumber"
            @listPhoneNumberInput="
              (newListPhoneNumber) => {
                listPhoneNumber = newListPhoneNumber;
              }
            "
            @search-contacts="searchContacts"
          ></collapse-accordion>
        </div>
        <!-- Record Per page, Export, Add to My Saved Contacts, Download Limit, Download Limit over message, Find People table (Contacts Table) -->
        <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left">
          <!-- Record Per page, Export, Add to My Saved Contacts, Download Limit -->
          <div>
            <vs-navbar class="nabarx mb-8 table-nav">
              <div slot="title">
                <vs-navbar-title> Find People </vs-navbar-title>
              </div>
              <vs-navbar-item index="2">
                 <dropdown-menu class="dropdown-export">
                  <button slot="trigger" class="dropdown-export-btn">
                    {{ LabelConstant.buttonLabelExport }}
                    <i class="vs-icon notranslate icon-scale material-icons null">expand_more</i>
                  </button>
                  <ul slot="body">
                    <li @click="exportContactType ='csv', getContactLimits('csv')">
                      {{ LabelConstant.buttonLabelExportCSV }}
                    </li>
                    <li @click="exportContactType ='excel', getContactLimits('excel')">
                       {{ LabelConstant.buttonLabelExportExcel }}
                    </li>
                    <!-- <li @click="CommingsoonPopup = true" v-if="initialRole =='Global Admin' && appRunningOnDev.toLowerCase() === 'true'">
                      {{ LabelConstant.buttonLabelExportCRM }}
                    </li> -->
                    <li v-if="appRunningOnDev.toLowerCase() === 'true' && enableZapierIntegration" @click="checkHasZapierContactSetup()">Selected to Zapier</li>
                    <li @click="savedContactQueryResultToCSV()">
                      {{ LabelConstant.buttonLabelExportQueryResultToCSV }}
                    </li>
                  </ul>
                </dropdown-menu>
                <!-- <vs-dropdown>
                  <a class="a-icon" href="#"> {{ LabelConstant.buttonLabelExport }}
                    <vs-icon class="" icon="expand_more"></vs-icon>
                  </a>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="exportContactType ='csv', getContactLimits('csv')"> {{ LabelConstant.buttonLabelExportCSV }}</vs-dropdown-item>
                    <vs-dropdown-item @click="exportContactType ='excel', getContactLimits('excel')"> {{ LabelConstant.buttonLabelExportExcel }} </vs-dropdown-item>
                    <vs-dropdown-item @click="CommingsoonPopup = true" v-if="initialRole =='Global Admin' && appRunningOnDev.toLowerCase() === 'true'">
                      {{ LabelConstant.buttonLabelExportCRM }}
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="savedContactQueryResultToCSV()" v-if="initialRole =='Global Admin' && appRunningOnDev.toLowerCase() === 'true'">
                      {{ LabelConstant.buttonLabelExportQueryResultToCSV }}
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown> -->
              </vs-navbar-item>
              <!-- <vs-navbar-item index="3" v-if="initialRole =='Global Admin' && appRunningOnDev.toLowerCase() === 'true'">
                <vs-dropdown>
                  <a class="a-icon" href.prevent>Tag
                    <vs-icon class="" icon="expand_more"></vs-icon>
                  </a>
                  <vs-dropdown-menu>
                   <vs-dropdown-item @click="addTagToSeleted(),tagModuleRequest = 'add'">
                      Add Tag to Selected
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="removeTagSelected(),tagModuleRequest='remove'">
                      Remove Tag from Selected
                    </vs-dropdown-item>
                    <vs-dropdown-item   @click="tagModuleRequest='add', listContactSavedTags(),manageTagsPopup = true"> Manage Tags </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-navbar-item> -->
              <vs-navbar-item index="1" :disabled="totalContactLimitRemaining <= 0" >
                <a class="py-0" @click="addToMySavedContacts()">{{ LabelConstant.buttonLabelSaveContacts }}</a>
              </vs-navbar-item>
               <v-select
                class=" show-tbl-data"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerMainPage"
                @input="searchPaginationSetTimeOut()"
              />
            </vs-navbar>
          </div>
          <!-- <div class="card-title more-card-title d-flex">
            <div><h2 class="mb-2">Find People</h2></div>
            <div>
              <v-select
                class="mr-2 mb-2 float-left show-tbl-data"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerMainPage"
              />
              <vs-button
                title="Export Query Results"
                color="secondary"
                type="filled"
                icon-pack="feather"
                icon="icon-arrow-down"
                class="mr-2 mb-2 px-2 float-left"
                >Export Query Results
              </vs-button>
              <vs-button
                title="Export Selected"
                color="gray"
                type="filled"
                icon-pack="feather"
                icon="icon-share"
                class="mr-2 mb-2 px-2 float-left"
                >Export Selected
              </vs-button>
              <vs-button
                :title="LabelConstant.buttonLabelExport"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-download"
                class="mr-2 mb-2 px-2 float-left"
                @click="openExportContactOptionsPopup = true"
                :disabled="totalContactLimitRemaining <= 0"
                >{{ LabelConstant.buttonLabelExport }}
              </vs-button>
              <vs-button
                :title="LabelConstant.buttonLabelAddtoMySavedContacts"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-users"
                class="mr-2 mb-2 px-2 float-left"
                @click="addToMySavedContacts()"
                :disabled="totalContactLimitRemaining <= 0"
                >{{ LabelConstant.buttonLabelAddtoMySavedContacts }}
              </vs-button>
              <vs-button
                :title="LabelConstant.buttonLabelDownloadLimit"
                color="warning"
                type="filled"
                class="mr-2 mb-2 px-2 float-left"
                >{{ LabelConstant.buttonLabelDownloadLimit }}:
                {{ totalContactLimitRemaining | tableRecordNumberFormatter }}
              </vs-button>
            </div>
          </div> -->
          <!-- Download Limit over message -->
          <div class="mb-2 sm:w-auto w-full flex flex-col" v-if="totalContactLimitRemaining <= 0">
            <p class="error-msg text-right w-auto">
              Your contact download limit is over.
            </p>
          </div>
          <!-- Find People table (Contacts Table) -->
          <div class="common-table pt-0" v-if="contacts && contacts.length > 0">
            <!-- Contacts table -->
            <vs-table multiple v-model="selectedMain" :data="contacts">
              <template slot="thead">
                <vs-th>{{ LabelConstant.tableHeaderLabelLogo }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelFullName }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelLinkedIn }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelTitle }}</vs-th>
                <vs-th>{{ dynamicLocationColumnName }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelCompany }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelSaved }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelPhone }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelEmail }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].CompanyLogoUrl">
                    <a
                      v-if=" data[indextr].CompanyName != '' && data[indextr].CompanyId != 0"
                      @click="companyDetailPopup = true, selectedCompanyId=data[indextr].CompanyId, getCompanyDetail(data[indextr].CompanyId)"
                    >
                      <img
                        :src="data[indextr].CompanyLogoUrl"
                        alt="Company Logo"
                        height="50"
                        width="50"
                      />
                    </a>
                    <img
                      v-else
                      :src="data[indextr].CompanyLogoUrl"
                      alt="Company Logo"
                      height="50"
                      width="50"
                    />
                  </vs-td>
                  <vs-td :data="data[indextr].fullName">
                    <div class="flex items-center">
                      <a
                        v-show="data[indextr].IsSavedContact == true"
                        @click="contactsDetailPopup = true, selectedContactId = data[indextr].Id, getSavedContactDetail(), tagModuleRequest = 'update'"
                      >
                        {{ data[indextr].fullName }}
                      </a>
                      <a
                        v-show="data[indextr].IsSavedContact == false"
                        @click=" selectedContactId = data[indextr].PersonId, openContactCreditPopup()"
                      >
                        {{ data[indextr].fullName }}
                      </a>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].fullName">
                    <a
                      v-show=" data[indextr].ContactLinkedinUrl && data[indextr].ContactLinkedinUrl != ''"
                      :href="'https://' + data[indextr].ContactLinkedinUrl"
                      target="_blank"
                    >
                      <vs-avatar
                        color="primary"
                        icon-pack="feather"
                        icon="icon-linkedin"
                        size="28px"
                      />
                    </a>
                  </vs-td>
                  <vs-td :data="data[indextr].ContactTitle">
                    {{ data[indextr].ContactTitle }}
                  </vs-td>
                  <vs-td :data="data[indextr].location">
                    {{ data[indextr].location }}
                    <div class="flex who-to-contact-help-icon">
                      <vx-tooltip title="HQ" color="secondary" :text="data[indextr].addressHq" v-if="data[indextr].HasHqAddress">
                        <vs-button type="flat" icon-pack="material-icons" icon="apartment" class="p-0 help-btn"></vs-button>
                      </vx-tooltip>
                      <vx-tooltip title="Local" color="secondary" :text="data[indextr].addressLocal" v-if="data[indextr].HasLocalAddress">
                        <vs-button type="flat" icon-pack="material-icons" icon="place" class="p-0 help-btn"></vs-button>
                      </vx-tooltip>
                      <vx-tooltip title="Physical" color="secondary" :text="data[indextr].addressPhysical" v-if="data[indextr].HasPhysicalAddress">
                        <vs-button type="flat" icon-pack="material-icons" icon="home_work" class="p-0 help-btn"></vs-button>
                      </vx-tooltip>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].CompanyName">
                    <a
                      v-if=" data[indextr].CompanyName != '' && data[indextr].CompanyId != 0"
                      @click="companyDetailPopup = true, selectedCompanyId=data[indextr].CompanyId, getCompanyDetail(data[indextr].CompanyId)"
                    >
                      {{ data[indextr].CompanyName }}
                    </a>
                  </vs-td>
                  <vs-td :data="data[indextr].IsSavedContact">
                    <span v-show="data[indextr].IsSavedContact == true">Yes</span>
                    <span v-show="data[indextr].IsSavedContact == false">No</span>
                  </vs-td>
                  <vs-td>
                    <a
                      v-show="data[indextr].IsSavedContact == false"
                      @click="selectedContactId = data[indextr].PersonId, openContactCreditPopup()"
                    >
                      <feather-icon
                        class="text-success"
                        icon="PhoneIcon"
                        v-show="data[indextr].HasDirectPhone"
                        title="Direct Phone"
                      ></feather-icon>
                      <feather-icon
                        class="text-warning"
                        icon="SmartphoneIcon"
                        v-show="data[indextr].HasMobilePhone"
                        title="Mobile Phone"
                      ></feather-icon>
                    </a>
                    <a
                      v-show="data[indextr].IsSavedContact == true"
                      @click="contactsDetailPopup = true, selectedContactId = data[indextr].Id, getSavedContactDetail(), tagModuleRequest = 'update'"
                    >
                      <feather-icon
                        class="text-success"
                        icon="PhoneIcon"
                        v-show=" data[indextr].HasDirectPhone && data[indextr].IsSavedContact == true"
                        title="Direct Phone"
                      ></feather-icon>
                      <feather-icon
                        class="text-warning"
                        icon="SmartphoneIcon"
                        v-show=" data[indextr].HasMobilePhone && data[indextr].IsSavedContact == true"
                        title="Mobile Phone"
                      ></feather-icon>
                    </a>
                  </vs-td>
                  <vs-td>
                    <a
                      v-show="data[indextr].IsSavedContact == false"
                      @click="selectedContactId = data[indextr].PersonId, openContactCreditPopup()"
                    >
                      <feather-icon
                        class="text-success"
                        icon="MailIcon"
                        v-show="data[indextr].HasCompanyEmail"
                        title="Company Email"
                      ></feather-icon>
                      <feather-icon
                        class="text-warning"
                        icon="MailIcon"
                        v-show="data[indextr].HasPersonalEmail"
                        title="Personal Email"
                      ></feather-icon>
                    </a>
                    <a
                      v-show="data[indextr].IsSavedContact == true"
                      @click="contactsDetailPopup = true, selectedContactId = data[indextr].Id, getSavedContactDetail(), tagModuleRequest = 'update'"
                    >
                      <feather-icon
                        class="text-success"
                        icon="MailIcon"
                        v-show=" data[indextr].HasCompanyEmail && data[indextr].IsSavedContact == true"
                        title="Company Email"
                      ></feather-icon>
                      <feather-icon
                        class="text-warning"
                        icon="MailIcon"
                        v-show=" data[indextr].HasPersonalEmail && data[indextr].IsSavedContact == true"
                        title="Personal Email"
                      ></feather-icon>
                    </a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- Total contacts and Pagination -->
            <div class="vx-row clear-both">
              <div class="vx-col w-full lg:w-full mt-4">
                <div class="flex items-center justify-between paginate-row flex-wrap">
                  <p class="table-data-entries-details">{{ LabelConstant.buttonLabelTotalContacts }} :
                    <span>{{ totalContacts | tableRecordNumberFormatter }}</span>
                  </p>
                  <!-- <paginate
                    ref="contactsMainPaginate"
                    :page-count="totalPages"
                    :click-handler="searchContactsByPagination"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :page-range="3"
                    :first-last-button="true"
                    :first-button-text="'|<'"
                    :last-button-text="'>|'"
                    :container-class="'paginate'"
                  >
                  </paginate>-->
                   <!-- <paginate
                    ref="contactsMainPaginate"
                    :page-count="1"
                    :click-handler="searchContactsByPagination"
                    :prevText="'< Prev'"
                    :nextText="'Next >'"
                    :page-range="1"
                    :first-last-button="false"
                    :container-class="'paginate'"
                  >
                  </paginate>-->
                  <b-pagination
                    ref="searchContactsMainPaginate"
                    pills
                    size="sm"
                    class="pr-3 bootstrap-pagination"
                    v-model="currentPage"
                    :total-rows="totalContacts"
                    :per-page="recordPerMainPage"
                    :limit="1"
                    prev-text="< Prev"
                    next-text="Next >"
                    :hide-goto-end-buttons="true"
                    @change="searchPaginationSetTimeOut()"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
          <!-- if no contacts found -->
          <div v-else>
            <div class="row pt-2 no-data-found">
              <div class="col-12 text-center">
                <div class="sm:w-1/4 w-1/2 mx-auto">
                  <img src="../../../src/assets/images/no-data.png" class="d-inline-block w-full" />
                </div>
                <h2>Oops! No contacts found with your search.</h2>
              </div>
              <div class="col-12 text-center">
                <div class="lg:w-1/2 md:w-2/3 w-full mx-auto text-left pt-3">
                  <h6 class="py-2">Suggestions</h6>
                  <ul class="pl-5">
                    <li>Try a more generalized search criteria.</li>
                    <li>Make sure your names and terms are spelled correctly.</li>
                    <li>Try different criteria</li>
                    <!-- <li class="link" @click="searchTipsPopup = true"> Try these search tips </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Download Excel -->
          <div v-show="false">
            <xlsx-workbook>
              <xlsx-sheet
                :collection="downloadContact.data"
                v-for="downloadContact in downloadContacts"
                :key="downloadContact.name"
                :sheet-name="downloadContact.name"
              />
              <xlsx-download :filename="'Contacts.csv'" :options="{ bookType: 'csv' }">
                <a ref="selectedToCsv"> {{ LabelConstant.buttonLabelExportCSV }} </a>
              </xlsx-download>
            </xlsx-workbook>
          </div>
          <!-- Download Excel -->
          <div v-show="false">
            <xlsx-workbook>
              <xlsx-sheet
                :collection="downloadContact.data"
                v-for="downloadContact in downloadContacts"
                :key="downloadContact.name"
                :sheet-name="downloadContact.name"
              />
              <xlsx-download :filename="'Contacts.xlsx'" :options="{ bookType: 'xlsx' }">
                <a ref="selectedToExcel"> {{ LabelConstant.buttonLabelExportExcel }} </a>
              </xlsx-download>
            </xlsx-workbook>
          </div>
          <!-- end if no contacts found -->
        </div>
      </div>
    </vx-card>
    <!-- Select Connection -->
    <find-any-one-select-connection
      :popupTitle="'Select Connection'"
      :connectionPopup="connectionPopup"
      :pushModule="pushModule"
      :selectedMain="selectedMain"
      :selectedInner="selectedMain"
      :applicationId="4"
      @cancelConnection="connectionPopup=false, selectedInner = [], selectedMain=[], pushModule=null"
    ></find-any-one-select-connection>
    <!-- search tips modal -->
    <find-any-one-search-tips-modal
      :popupTitle="'Search Tips'"
      :isPopup="searchTipsPopup"
      @searchTipsPopupIput="(newSearchTipsPopup) => {searchTipsPopup = newSearchTipsPopup;}"
    ></find-any-one-search-tips-modal>
    <!-- Export Contact options modal (after selecting contacts and clicking on Export button this will open) -->
    <find-any-one-export-options-modal
      :popupTitle="'Export Contacts'"
      :isPopup="openExportContactOptionsPopup"
      @openExportContactOptionsPopupInput="(newOpenExportContactOptionsPopup) => {openExportContactOptionsPopup = newOpenExportContactOptionsPopup;}"
      @exportContactTypeInput="(newExportContactType) => {exportContactType = newExportContactType;}"
      @clickForGetContactLimits="getContactLimits()"
      @contactpushTocrm="(openExportContactOptionsPopup = false,connectionPopup = true,pushModule = 'contact')"
    ></find-any-one-export-options-modal>
    <!-- Download Excel contacts (After getting export contacts use can download CSV or EXCEL) -->
    <find-any-one-download-final
      :popupTitle="'Download Contacts'"
      :isPopup="downloadExcelContactPopup"
      :sheetOptions="downloadContacts"
      :excelFileName="'Contacts.xlsx'"
      :excelOptions="{bookType: 'xlsx'}"
      :csvFileName="'Contacts.csv'"
      :csvOptions="{bookType: 'csv'}"
      :whichOptionShow="exportContactType"
      @clickForFinish="(downloadExcelContactPopup = false, exportContactType = null)"
      @downloadPushTOCRM="(downloadExcelContactPopup = false,connectionPopup = true,pushModule = 'contact')"
      :showPushtoCRM="true"
    ></find-any-one-download-final>
    <!-- Add Update tag popup -->
    <find-any-one-add-update-tag-modal
      ref="addUpdateTag"
      :isAddUpdateTagPopupActive="addUpdateTagPopup"
      @saveNewTag="saveUpdateTag"
      @closeAddUpdateTagPopup="closeAddUpdateTagPopup"
      :tagDetails="userDefinedTagDetails"
    ></find-any-one-add-update-tag-modal>
    <!-- Add  tag To contactPopup -->
    <find-any-one-add-tag-to-selected-modal
      ref="addTagToSelectedContact"
      :isAddTagsPopupActive="addTagsPopup"
      :userDefinedTagsOptions="selectTagsOptions"
      @closePopupAddTagToSelected="addTagsPopup = false"
      @addTagToContacts="addTagToContacts"
      @createNewTag="addUpdateTagPopup = true"
      :requestFromModule="tagModuleRequest"
      @updateTagToContacts="updateTagsContactDetails"
      @removeTagToContacts="removeTagsFromContactConfirmation"
    ></find-any-one-add-tag-to-selected-modal>
    <!-- remove tag -->
    <find-any-one-remove-tag-modal
      :isRemoveTagPopupActive ="removeTagPopup"
      @clickCloseRemoveTagPopup="removeTagPopup = false,companyDetailPopup?selectedInner=[]:selectedMain=[]"
      @removeTagsFromContact="removeTagsFromContact"
    ></find-any-one-remove-tag-modal>
    <!-- manage tag -->
    <find-any-one-manage-tag-modal
      :isManageTagsPopupActive="manageTagsPopup"
      :userDefinedTagsList="selectTagsOptions"
      @closeManageTagPopup="manageTagsPopup = false"
      @clickOnDeleteTag="deleteUserDefinedTag"
      @createNewTag="addUpdateTagPopup = true"
      @editUserDefinedTag="editUserDefinedTag"
    ></find-any-one-manage-tag-modal>
    <!-- Credit warning popup (If there are enough contact limit then this will open) -->
    <find-any-one-credit-warning-modal
      :popupTitle="'Contact Credits'"
      :isPopup="creditWarningPopup"
      :isShowCreditDeduction="isCreditDeductionEnable"
      :creditDeductionMessage="numberOfCreditMessage"
      :isCompanyDetailPopup="companyDetailPopup"
      :isSelectedMainLength="selectedMain.length > 0"
      :selectedContactId="selectedContactId"
      :exportContactType="exportContactType"
      @queryToCSV="creditWarningPopup= false,numberOfCreditMessage= null,isCreditDeductionEnable = false,exportContactType=null,addJobNamePopup=true"
      :isSelectedInnerLength="selectedInner.length > 0"
      @getContactDetail="creditWarningPopup = false,numberOfCreditMessage=null,isCreditDeductionEnable=false,tagModuleRequest='contactdetails',addTagToContacts(tags = {contactSelectedTags:[],isExportContact:false})"
      @exportContacts="creditWarningPopup = false,numberOfCreditMessage=null,isCreditDeductionEnable=false,listContactSavedTags(),tagModuleRequest='export',addTagsPopup = true"
      @clickForFinish="creditWarningPopup= false,numberOfCreditMessage= null,isCreditDeductionEnable = false,selectedMain= [],selectedInner= []"
    ></find-any-one-credit-warning-modal>
    <!-- Contacts details modal -->
    <find-any-one-contact-details-modal
      :contactsDetailPopup="contactsDetailPopup"
      @clickForClose="closeContactDetailsPopup()"
      @editTagsForContact="editTagsForContact()"
      :contactDetails="contactDetails"
      moduleName="searchContact"
    ></find-any-one-contact-details-modal>
    <!-- credit limit warning popup (If there are no enough contact limit then this will open) -->
    <find-any-one-credit-limit-warning-modal
      :popupTitle="'Contact Credits'"
      :isPopup="creditLimitWarningPopup"
      @clickForFinish="creditLimitWarningPopup = false"
    ></find-any-one-credit-limit-warning-modal>
    <!-- Select contact warning for add to my saved -->
    <find-any-one-select-contact-add-to-saved-warning-modal
      :popupTitle="'Contact Credits'"
      :isPopup="selecteContactWarningForAddToMySavedPopup"
      :selectContactWarningMessage="selecteContactWarningForAddToMySavedPopupMessage"
      @clickForFinish="selecteContactWarningForAddToMySavedPopup = false"
    ></find-any-one-select-contact-add-to-saved-warning-modal>
    <!-- Credit warning for add to my saved -->
    <find-any-one-credit-warning-add-to-saved-modal
      :popupTitle="'Contact Credits'"
      :isPopup="openAddToMySavedContactsCreditPopup"
      :creditWarningMessage="openAddToMySavedContactsCreditPopupMessage"
      @clickForYes="getContactLimitsForAddToMySavedContacts()"
      @clickForNo="cancelAddToMySavedContacts()"
    ></find-any-one-credit-warning-add-to-saved-modal>
    <!-- Success massage for add to my saved -->
    <find-any-one-success-message-add-to-saved-modal
      :popupTitle="'Contact Credits'"
      :isPopup="addToMySavedContactsSuccessPopup"
      @clickForFinish="addToMySavedContactsSuccessPopup = false"
    ></find-any-one-success-message-add-to-saved-modal>
    <!-- Job name -->
    <find-any-one-data-enrichment-jobname
      ref="enrichmentJobName"
      :isaddJobNamePopup="addJobNamePopup"
      @clickOnSave="runDataEnrichmentJobQueryResultToCSV"
      @clickForClose="closeDataEnrichmentJobNamePopup"
      :jobNameValue="dataEnrichmentJobName"
      @jobNameInput="
        (newJobName) => {
          dataEnrichmentJobName = newJobName;
        }
      "
      :showJobNameErrorMessage="showJobNameErrorMessage"
      :jobNameErrorMessage="jobNameErrorMessage"
    ></find-any-one-data-enrichment-jobname>
    <!-- Job message -->
    <find-any-one-data-enrichment-export-query-message
      :isDataEnrichmentJobMessagePopup="exportJobRequestedMessage"
      :message="jobMessage"
      @clickForClose="exportJobRequestedMessage = false,jobMessage=''"
    ></find-any-one-data-enrichment-export-query-message>
    <!-- Download limit is exceeded -->
    <find-any-one-exceeded-download-limit-modal
      :popupTitle="'Contact Credits'"
      :isPopup="isExceededDownloadLimit"
      :downloadExceededMessage="downloadExceededMessage"
      @clickForClose="isExceededDownloadLimit = false"
    ></find-any-one-exceeded-download-limit-modal>
    <!-- Company details popup -->
    <find-any-one-company-details-modal
      ref="searchContactsRef"
      :companyDetailPopup="companyDetailPopup"
      @clickForClose="closeCompanyDetailPopup()"
      :companyDetail="companyDetail"
      :showEntriesOptions="showEntriesOptions"
      :recordPerInnerPageValue="recordPerInnerPage"
      @recordPerInnerPageInput="(newRecordPerInnerPage) => {recordPerInnerPage = newRecordPerInnerPage;}"
      @clickForExport="getContactLimits"
      @exportType="(newExportType) => {
        searchContactExportType = newExportType;
      }"
      :isShowAddToMySavedContactsButton="true"
      :disabledExportORAddToMySavedContactsORDownload="totalContactLimitRemaining <= 0"
      @clickForAddToMySavedContacts="addToMySavedContacts()"
      :selectedInnerValue="selectedInner"
      @selectedInnerInput="(newSelectedInner) => {selectedInner = newSelectedInner;}"
      @contactsDetailPopupInput="(newContactsDetailPopup) => {contactsDetailPopup = newContactsDetailPopup;
          tagModuleRequest = 'update';
      }"
      @selectedContactIdInput="(newSelectedContactId) => {selectedContactId = newSelectedContactId;}"
      @clickForGetSavedContactDetail="getSavedContactDetail()"
      @clickForOpenContactCreditPopup="openContactCreditPopup()"
      :companyTotalContacts="companyTotalContacts"
      :companyTotalPages="companyTotalPages"
      @clickForGetCompanyDetailByPagination="getCompanyDetailByPagination"
      :jobLevelOptions="jobLevelOptions"
      :jobFunctionOptions="jobFunctionOptions"
      :jobStartDateOptions="jobStartDateOptions"
      :contactRegionOptions="contactRegionOptions"
      :addressTypeOptions="addressTypeOptions"
      :contactCountriesOptions="contactCountriesOptions"
      :contactStatesOptions="contactStatesOptions"
      :personalContactGenderOptions="personalContactGenderOptions"
      :selectedCompanyId="selectedCompanyId"
    ></find-any-one-company-details-modal>
    <!-- Comming soon -->
    <vs-popup title="Coming soon" :active.sync="CommingsoonPopup">
      <template>
        <vx-card>
          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <div class="coming-soon sm:w-1/2 w-full py-5 mx-auto">
                <img src="../../../src/assets/images/maintance.png" class="d-inline-block w-full" />
                <h4 class="text-primary py-5">Coming soon</h4>
              </div>
            </div>
          </div>
        </vx-card>
        <div class="text-center float-right mt-6 mb-8">
          <vs-button color="primary" type="border" @click="CommingsoonPopup = false">{{ LabelConstant.buttonLabelOkay }}</vs-button>
        </div>
      </template>
    </vs-popup>
    <!-- Zapier Message -->
    <find-any-one-zapier-set-up-warning-message
      :popupTitle="zapierPopupTitle"
      :isPopup="isZapierSetupWarningPopup"
      :message="zapierSetupMessage"
      @clickForClose="isZapierSetupWarningPopup = false, zapierPopupTitle = '', zapierSetupMessage = ''"
    ></find-any-one-zapier-set-up-warning-message>
  </div>
</template>

<script>
import Vue from "vue";
import CollapseAccordion from "../../components/collapse/CollapseAccordion";
import InputTag from "vue-input-tag";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);
import vSelect from "vue-select";
import findAnyoneSearchContactsMixin from "../../mixins/findAnyoneSearchContactsMixin";
import findAnyoneMixin from "../../mixins/findAnyoneMixin";
import FindAnyOneContactDetailsModal from "../../components/modal/FindAnyOneContactDetailsModal.vue";
import FindAnyOneCompanyDetailsModal from "../../components/modal/FindAnyOneCompanyDetailsModal.vue";
import FindAnyOneExportOptionsModal from '../../components/modal/FindAnyOneExportOptionsModal.vue'
import FindAnyOneSearchTipsModal from  '../../components/modal/FindAnyOneSearchTipsModal.vue'
import FindAnyOneDownloadFinal from '../../components/modal/FindAnyOneDownloadFinal.vue'
import FindAnyOneCreditWarningModal from '../../components/modal/FindAnyOneCreditWarningModal.vue'
import FindAnyOneCreditLimitWarningModal from '../../components/modal/FindAnyOneCreditLimitWarningModal.vue'
import FindAnyOneSelectContactAddToSavedWarningModal from '../../components/modal/FindAnyOneSelectContactAddToSavedWarningModal.vue'
import FindAnyOneCreditWarningAddToSavedModal from '../../components/modal/FindAnyOneCreditWarningAddToSavedModal.vue'
import FindAnyOneSuccessMessageAddToSavedModal from '../../components/modal/FindAnyOneSuccessMessageAddToSavedModal.vue'
import FindAnyOneSelectConnection from "../../components/modal/FindAnyOneSelectConnection.vue"
import FindAnyOneAddTagToSelectedModal from "../../components/modal/FindAnyOneAddTagToSelectedModal.vue";
import FindAnyOneManageTagModal from "../../components/modal/FindAnyOneManageTagModal.vue";
import FindAnyOneAddUpdateTagModal from "../../components/modal/FindAnyOneAddUpdateTagModal.vue";
import FindAnyOneRemoveTagModal from "../../components/modal/FIndAnyOneRemoveTagModal.vue"
import FindAnyOneDataEnrichmentJobname from '../../components/modal/FindAnyOneDataEnrichmentJobname.vue'
import FindAnyOneDataEnrichmentExportQueryMessage from '../../components/modal/FindAnyOneDataEnrichmentExportQueryMessage.vue'
import FindAnyOneExceededDownloadLimitModal from "../../components/modal/FindAnyOneExceededDownloadLimitModal.vue"
import FindAnyOneZapierSetUpWarningMessage from '../../components/modal/FindAnyOneZapierSetUpWarningMessage.vue'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { BPagination } from 'bootstrap-vue'
import VueJwtDecode from "vue-jwt-decode";
//import Cookie from "js-cookie";
import DropdownMenu from "v-dropdown-menu";
import "v-dropdown-menu/dist/v-dropdown-menu.css";

export default {
  mixins: [findAnyoneSearchContactsMixin, findAnyoneMixin],
  components: {
    CollapseAccordion,
    InputTag,
    "v-select": vSelect,
    FindAnyOneContactDetailsModal,
    FindAnyOneCompanyDetailsModal,
    FindAnyOneExportOptionsModal,
    FindAnyOneSearchTipsModal,
    FindAnyOneDownloadFinal,
    FindAnyOneCreditWarningModal,
    FindAnyOneCreditLimitWarningModal,
    FindAnyOneSelectContactAddToSavedWarningModal,
    FindAnyOneCreditWarningAddToSavedModal,
    FindAnyOneSuccessMessageAddToSavedModal,
    FindAnyOneSelectConnection,
    XlsxWorkbook, XlsxSheet, XlsxDownload,
    BPagination,
    VueJwtDecode,
    FindAnyOneAddTagToSelectedModal,
    FindAnyOneManageTagModal,
    FindAnyOneAddUpdateTagModal,
    FindAnyOneRemoveTagModal,
    FindAnyOneDataEnrichmentJobname,
    FindAnyOneDataEnrichmentExportQueryMessage,
    FindAnyOneExceededDownloadLimitModal,
    FindAnyOneZapierSetUpWarningMessage,
    DropdownMenu
  },
  data() {
    return {
      //Set to show and hide the export tag menu's
      appRunningOnDev:process.env.VUE_APP_RUNNING_ON_DEV?process.env.VUE_APP_RUNNING_ON_DEV:'',
      initialRole:'',
      currentPage:1,
      CommingsoonPopup: false,
      connectionPopup:false,
      pushModule:null,
      enableZapierIntegration: false,
    }
  },
  created() {
    this.decodeJWT();
    let uid = this.$route.params.id
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    this.enableZapierIntegration = token[uid].enableZapierIntegration
  },
  methods: {
    decodeJWT() {
      let uid = this.$route.params.id;
      let jwtDecode = VueJwtDecode.decode(
        this.$store.state.authTokenVV[uid].token
      );
      this.initialRole = jwtDecode.initialRole;
    },
  },
};
</script>
